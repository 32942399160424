<script setup lang="ts">
import ViewLayout from '@/components/ViewLayout.vue'
import { PawButtonFilled, PawHeading } from '@lyka-pet-food/lyka-common'
import { RetrieveDogReturnDto } from '@repo/api-types/src/entities'
import { computed } from 'vue'
import { useSubscriptionsStore } from '@/stores/subscriptions'

const props = defineProps<{
  dog?: RetrieveDogReturnDto
}>()

const emits = defineEmits<{
  (e: 'confirm'): void
}>()

const subscriptionsStore = useSubscriptionsStore()
const subscriptionForPet = computed(() => subscriptionsStore.getSubscriptionByPetId(props.dog?.identifier as string))
</script>
<template>
  <ViewLayout>
    <div class="tw-flex tw-w-full tw-max-w-[1140px] tw-space-y-8 tw-pb-48 tw-pt-12 tw-justify-center">
      <div class="tw-flex tw-justify-center tw-items-center tw-flex-col tw-space-y-12 tw-max-w-[400px]">
        <img src="@/assets/icons/success-tick.png" class="tw-w-20 tw-h-auto" />
        <PawHeading size="2xl">Your current plan is just right</PawHeading>

        <div class="tw-w-full">
          <div
            class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-rounded-xl tw-bg-white tw-p-4 tw-py-6 tw-space-y-4"
          >
            <div class="tw-bg-surface-brand-secondary tw-p-1 tw-px-2 tw-rounded-lg">Current</div>
            <img class="tw-max-w-[250px]" src="@/assets/images/meals/bowl-general.png" alt="bowl-general" />
            <div class="tw-text-center">
              <div class="tw-font-bold tw-text-xl">{{ subscriptionForPet?.dailyServingSizeInGrams }}g per day</div>
              <div>Feeding 100% Lyka</div>
            </div>
          </div>
        </div>

        <div><span class="tw-font-bold">Still unsure?</span> Contact our support team</div>

        <div class="tw-w-full">
          <PawButtonFilled class="tw-w-full" @click="emits('confirm')">Okay</PawButtonFilled>
        </div>
      </div>
    </div>
  </ViewLayout>
</template>
