import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useDogs } from '@/composables/useDogs'
import {
  RetrieveDogReturnDto,
  DogBreedReturnDto,
  DogHealthIssueReturnDto,
  DogFoodTypeReturnDto,
  DogFoodAllergenReturnDto,
} from '@repo/api-types/src/entities'

export const useAnimalsStore = defineStore('animals', () => {
  const dogs = ref<RetrieveDogReturnDto[]>([])
  const breeds = ref<DogBreedReturnDto[]>([])
  const healthIssues = ref<DogHealthIssueReturnDto[]>([])
  const foodTypes = ref<DogFoodTypeReturnDto[]>([])
  const foodAllergens = ref<DogFoodAllergenReturnDto[]>([])
  const loading = ref(false)

  const loadDogs = async () => {
    const { returnedDogs, error } = await useDogs().getDogs()

    // TODO: Move to error  handling
    if (error) {
      console.error(error)
      return
    }

    if (returnedDogs) {
      dogs.value = returnedDogs
    }
  }

  const loadDogsData = async () => {
    const { data, error } = await useDogs().getDogsData()

    // TODO: Move to error  handling
    if (error) {
      console.error(error)
      return
    }

    if (data) {
      breeds.value = data.breeds
      healthIssues.value = data.healthIssues
      foodTypes.value = data.foodTypes
      foodAllergens.value = data.foodAllergens
    }
  }

  const load = async () => {
    loading.value = true
    await Promise.all([loadDogs(), loadDogsData()])
    loading.value = false
  }

  const getDogById = (petId: string) => {
    return dogs.value?.find((animal) => animal.identifier === petId)
  }

  const getAllergenById = (id: string) => {
    return foodAllergens.value?.find((i) => i.identifier === id)
  }

  const getFoodTypeById = (id: string) => {
    return foodTypes.value?.find((i) => i.identifier === id)
  }

  const getHealthIssueById = (id: string) => {
    return healthIssues.value?.find((i) => i.identifier === id)
  }

  const getAllergenBySlug = (slug: string) => {
    return foodAllergens.value?.find((i) => i.slug === slug)
  }

  const getFoodTypeBySlug = (slug: string) => {
    return foodTypes.value?.find((i) => i.slug === slug)
  }

  const getHealthIssueBySlug = (slug: string) => {
    return healthIssues.value?.find((i) => i.slug === slug)
  }

  const breedsToSelectOptions = () => {
    return breeds.value?.map((opt) => {
      return {
        text: opt.name,
        value: opt.slug,
      }
    })
  }

  return {
    getDogById,
    getAllergenById,
    getFoodTypeById,
    getHealthIssueById,
    getAllergenBySlug,
    getFoodTypeBySlug,
    getHealthIssueBySlug,
    breedsToSelectOptions,
    load,
    loadDogs,
    loading,
    dogs,
    breeds,
    healthIssues,
    foodTypes,
    foodAllergens,
  }
})
