<script setup lang="ts">
import { IonHeader, IonToolbar } from '@ionic/vue'
import { LogoLyka } from '@lyka-pet-food/lyka-common'
import { useAuthStore } from '@/stores/auth'
import MenuButtons from '@/components/MenuButtons.vue'
import { useRouter } from 'vue-router'

const authStore = useAuthStore()
const router = useRouter()

const onClickLogo = () => {
  router.push({ name: 'deliveries' })
}
</script>

<template>
  <ion-header
    mode="md"
    :class="{
      'ion-no-border !tw-shadow-none': !authStore.isAuthenticated,
    }"
  >
    <ion-toolbar>
      <div class="tw-flex tw-h-20 tw-items-center tw-justify-between xl:tw-px-40 lg:tw-px-32 tw-px-4">
        <LogoLyka :light="false" class="tw-h-9 tw-justify-self-start tw-cursor-pointer" @click="onClickLogo" />
        <MenuButtons v-if="authStore.isAuthenticated" />
      </div>
    </ion-toolbar>
  </ion-header>
</template>

<style scoped>
.header-md {
  box-shadow: 0px 4px 8px 0px rgba(161, 154, 139, 0.15);
}
</style>
