<script setup lang="ts">
import { ref, computed } from 'vue'
import { useAnimalsStore } from '@/stores/animals'
import Deliveries from '@/views/Deliveries.vue'
import { useOrdersStore } from '@/stores/orders'
import { useProductsStore } from '@/stores/products'
import { useSubscriptionsStore } from '@/stores/subscriptions'
import { LineItem, Order, BillingCycleOrder, SlotAvailability } from '@repo/api-types'
import { ProductType } from '@/types/products'
import { IonPage } from '@ionic/vue'
import { EditProductMeal } from '@/views/EditMeals.vue'
import { type ProductToEdit } from '@/views/AddProducts.vue'
import { useOrders, type UpdateProductVariant } from '@/composables/useOrders'
import { useAppStateStore } from '@/stores/appState'
import { AvailableSlotName, AvailableDates, DeliveryTime } from '@/types/delivery'
import { PawDialogBox } from '@lyka-pet-food/lyka-common'
import AddProducts from '@/views/AddProducts.vue'
import EditProducts from '@/views/EditProducts.vue'
import EditMeals from '@/views/EditMeals.vue'
import SelectDeliveryDateModal from '@/components/selectDeliveryDateModal.vue'
import { loadingWrapper } from '@/utils/function'

interface EditDeliveryDate {
  date: string
  slot: string
  order: BillingCycleOrder
}

interface ProductToAdd {
  id?: string
  productVariantId: string
  quantity: number
  customAttributes?: { oneOff?: boolean }
}

const editMealsToggle = ref(false)
const editDogMeals = ref<LineItem[]>()
const editMealsOrder = ref<BillingCycleOrder>()

const editDeliveryDateToggle = ref(false)
const editDeliveryDate = ref<EditDeliveryDate>()
const editDeliveryAvailableDates = ref<AvailableDates[]>([])

const addProductsToggle = ref(false)
const addDogProducts = ref<LineItem[]>()

const updateProductsOrder = ref<BillingCycleOrder>()

const editProductsToggle = ref(false)
const editDogProducts = ref<LineItem[]>()

const editCancelWarning = ref(false)

const ordersStore = useOrdersStore()
const productsStore = useProductsStore()
const animalsStore = useAnimalsStore()
const subscriptionsStore = useSubscriptionsStore()
const appStateStore = useAppStateStore()

const loading = ref(false)
const setLoading = (isLoading: boolean) => (loading.value = isLoading)

const onCancelEditMeals = () => {
  toggleEditCancelWarningDialog()
}

const onCancelAddProducts = () => {
  addProductsToggle.value = false
}

const onCancelEditProducts = () => {
  editProductsToggle.value = false
}

const onConfirmAddProducts = async (updatedProducts: Array<ProductToEdit>) => {
  const orderSubscriptionContractId = updateProductsOrder.value?.subscriptionContractId
  const orderCycleIndex = updateProductsOrder.value?.cycleIndex
  const futureOrderIndex = ordersStore.futureOrders.findIndex((x) => x.cycleIndex === orderCycleIndex)

  if (!orderSubscriptionContractId || !orderCycleIndex || futureOrderIndex === -1) {
    return
  }

  let productsToAdd = [] as ProductToAdd[]

  updatedProducts.forEach((x) => {
    const productVariant = x?.productId && productsStore.getProductVariantByProductId(x.productId)

    if (!productVariant || !productVariant.productVariantId) return

    const lineItem = updateProductsOrder.value?.lines?.find(
      (lineItem) => lineItem.productVariantId === productVariant?.productVariantId,
    )

    if (lineItem) {
      // Product in line item
      // 1.1 product in order is subscription && user adds additional product to order as subscription
      // -> keep original, update quantity
      // 1.2 product in order is subscription && user adds additional product to order as one off
      // -> keep original subscription, add additional product as one off

      // 2.1 product in order is NOT subscription && user adds additional product to order as subscription
      // -> keep original as one off, add new product as subscription
      // 2.2 product in order is NOT subscription && user adds additional product to order as one off
      // -> keep original, update quantity
      if (lineItem.oneOff === false) {
        if (x.subscriptionFrequency !== 0) {
          // 1.1
          // Combine new product with original product as subscription
          productsToAdd.push({
            id: lineItem.lineItemId,
            productVariantId: productVariant.productVariantId,
            quantity: lineItem.quantity + x.quantity,
            customAttributes: {},
          })
        } else {
          // 1.2
          // First add back original product as subscription
          productsToAdd.push({
            id: lineItem.lineItemId,
            productVariantId: productVariant.productVariantId,
            quantity: lineItem.quantity,
            customAttributes: {},
          })

          // Second add new product as one off
          if (x.quantity === 0) return

          productsToAdd.push({
            productVariantId: productVariant.productVariantId,
            quantity: x.quantity,
            customAttributes: { oneOff: true },
          })
        }
      } else {
        if (x.subscriptionFrequency !== 0) {
          // 2.1
          // First add back original product as one off
          productsToAdd.push({
            id: lineItem.lineItemId,
            productVariantId: productVariant.productVariantId,
            quantity: lineItem.quantity,
            customAttributes: { oneOff: true },
          })

          // Then add new product as subscription
          if (x.quantity === 0) return

          productsToAdd.push({
            productVariantId: productVariant.productVariantId,
            quantity: x.quantity,
            customAttributes: {},
          })
        } else {
          // 2.2
          // Combine new product with original product as one off
          productsToAdd.push({
            id: lineItem.lineItemId,
            productVariantId: productVariant.productVariantId,
            quantity: lineItem.quantity + x.quantity,
            customAttributes: { oneOff: true },
          })
        }
      }
    } else {
      if (x.quantity === 0) return

      // Product not in line item
      // 3. new product is subscription
      // -> add product as subscription
      if (x.subscriptionFrequency !== 0) {
        productsToAdd.push({
          productVariantId: productVariant.productVariantId,
          quantity: x.quantity,
          customAttributes: {},
        })
      } else {
        // 4. new product is not subscription
        // -> add product as one off
        productsToAdd.push({
          productVariantId: productVariant.productVariantId,
          quantity: x.quantity,
          customAttributes: { oneOff: true },
        })
      }
    }
  })

  // Add meals as subscription
  updateProductsOrder.value?.lines?.forEach((lineItem) => {
    const productId = productsStore.getProductByProductVariantId(lineItem.productVariantId)?.productId
    if (productMeals.value?.find((x) => x?.productId === productId)) {
      productsToAdd.push({
        productVariantId: lineItem.productVariantId,
        quantity: lineItem.quantity,
        id: lineItem.lineItemId,
        customAttributes: {},
      })
    }
  })

  const subscriptionToAdd = productsToAdd.filter((x) => !x.customAttributes?.oneOff === true)
  const oneOffToAdd = productsToAdd.filter((x) => x.customAttributes?.oneOff === true)

  let updateResult
  appStateStore.updateLoadingState(true)

  // First adding subscriptions products
  const addSubscriptionResult = await useOrders().addProducts(orderSubscriptionContractId, subscriptionToAdd)

  // Then update one off products
  let addOneOffResult
  if (oneOffToAdd.length !== 0) {
    addOneOffResult = await useOrders().updateProducts(orderSubscriptionContractId, orderCycleIndex, productsToAdd)
  }

  if (addSubscriptionResult.error || addOneOffResult?.error) {
    alert('Failed to add products')
    return
  }

  await ordersStore.load()
  await subscriptionsStore.load()

  appStateStore.updateLoadingState(false)
  appStateStore.updateSuccessState(true)
  setTimeout(() => {
    appStateStore.updateSuccessState(false)
    addProductsToggle.value = false
  }, 800)
}

const onConfirmEditProducts = async (updatedProducts: Array<LineItem>) => {
  const orderSubscriptionContractId = updateProductsOrder.value?.subscriptionContractId
  const orderCycleIndex = updateProductsOrder.value?.cycleIndex
  const futureOrderIndex = ordersStore.futureOrders.findIndex((x) => x.cycleIndex === orderCycleIndex)

  if (!orderSubscriptionContractId || !orderCycleIndex || futureOrderIndex === -1) {
    return
  }

  let productsToEdit = [] as ProductToAdd[]
  updateProductsOrder.value?.lines?.forEach((lineItem) => {
    const productId = productsStore.getProductByProductVariantId(lineItem.productVariantId)?.productId

    // Add back all the meals
    if (productMeals.value?.find((x) => x?.productId === productId)) {
      productsToEdit.push({
        id: lineItem.lineItemId,
        quantity: lineItem.quantity,
        productVariantId: lineItem.productVariantId,
        customAttributes: { oneOff: lineItem.oneOff },
      })
    }
  })

  updatedProducts
    .filter((x) => x.quantity > 0)
    .forEach((item) => {
      productsToEdit.push({
        id: item.lineItemId,
        quantity: item.quantity,
        productVariantId: item.productVariantId,
        customAttributes: { oneOff: item.oneOff },
      })
    })

  const subscriptionProductToEdit = productsToEdit.filter((x) => x.customAttributes?.oneOff === false)

  let productSubscriptionResult

  appStateStore.updateLoadingState(true)

  if (subscriptionProductToEdit.length) {
    productSubscriptionResult = await useOrders().addProducts(orderSubscriptionContractId, subscriptionProductToEdit)
  }

  const productEditOneOffResult = await useOrders().updateProducts(
    orderSubscriptionContractId,
    orderCycleIndex,
    productsToEdit,
  )

  if (productEditOneOffResult?.error || productSubscriptionResult?.error) {
    alert('Failed to update products')
    return
  }

  await ordersStore.load()
  await subscriptionsStore.load()

  appStateStore.updateLoadingState(false)

  appStateStore.updateSuccessState(true)
  setTimeout(() => {
    appStateStore.updateSuccessState(false)
    editProductsToggle.value = false
  }, 1000)
}

const onConfirmEditMeals = async (updatedProducts: Array<EditProductMeal>) => {
  const orderSubscriptionContractId = editMealsOrder.value?.subscriptionContractId
  const orderCycleIndex = String(editMealsOrder.value?.cycleIndex)

  const subscriptionContract = subscriptionsStore.userSubContracts?.find((x) => x.id === orderSubscriptionContractId)
  const subscription = subscriptionsStore.userSubscriptions?.find(
    (x) => x.subscriptionId === subscriptionContract?.subscriptionId,
  )
  const subscriptionPouchSize = subscription?.pouchSizeInGrams

  if (!orderSubscriptionContractId || !orderCycleIndex || !subscriptionPouchSize) {
    return
  }

  let updatedVariants = [] as Array<UpdateProductVariant>
  updatedProducts.forEach((x) => {
    const productVariant =
      x?.productId && productsStore.getProductVariantByProductIdPouchSize(x.productId, subscriptionPouchSize)

    if (productVariant && productVariant.productVariantId) {
      updatedVariants.push({
        productVariantId: productVariant.productVariantId,
        quantity: x?.quantity,
      })
    }
  })

  appStateStore.updateLoadingState(true)

  const updateResult = await useOrders().updateMeals(orderSubscriptionContractId, orderCycleIndex, updatedVariants)

  if (!updateResult.error) {
    await ordersStore.load()
    await subscriptionsStore.load()
  }

  appStateStore.updateLoadingState(false)
  appStateStore.updateSuccessState(true)
  editMealsToggle.value = false
  setTimeout(() => {
    appStateStore.updateSuccessState(false)
  }, 500)
}

const onEditFutureOrderMeals = (lineItems: LineItem[], order: BillingCycleOrder) => {
  editDogMeals.value = lineItems
  editMealsOrder.value = order
  editMealsToggle.value = true
}

const onAddFutureOrderProducts = (lineItems: LineItem[], order: BillingCycleOrder) => {
  addDogProducts.value = lineItems
  updateProductsOrder.value = order
  addProductsToggle.value = true
}

const onAddFutureOrderProductsCloseEditProducts = (
  lineItems: LineItem[] | undefined,
  order: BillingCycleOrder | undefined,
) => {
  addDogProducts.value = lineItems
  updateProductsOrder.value = order
  editProductsToggle.value = false
  addProductsToggle.value = true
}

const onEditFutureOrderProducts = (lineItems: LineItem[], order: BillingCycleOrder) => {
  editDogProducts.value = lineItems
  updateProductsOrder.value = order
  editProductsToggle.value = true
}

const onEditFutureOrderDeliveryDate = (date: string, slot: string, order: BillingCycleOrder) =>
  loadingWrapper(async () => {
    if (!order.shippingAddress.postCode || !order.shippingAddress.city) {
      throw new Error('Order is missing postcode or city')
    }
    editDeliveryDateToggle.value = true
    editDeliveryDate.value = { date, slot, order }

    const { error, deliverySchedule } = await useOrders().getAvailableDeliveries(
      order.shippingAddress.postCode,
      order.shippingAddress.city,
    )

    if (error || !deliverySchedule) {
      throw new Error('Could not get delivery schedule')
    }

    const deliveryScheduleMapped = deliverySchedule.map((x) => {
      if (x.slotAvailability === SlotAvailability.BothAvailable)
        return {
          date: x.deliveryDate,
          timeSlots: [{ slot: AvailableSlotName.AM }, { slot: AvailableSlotName.PM }],
        }

      if (x.slotAvailability === SlotAvailability.AmAvailable)
        return {
          date: x.deliveryDate,
          timeSlots: [{ slot: AvailableSlotName.AM }, { slot: AvailableSlotName.PM, disabled: true }],
        }

      if (x.slotAvailability === SlotAvailability.PmAvailable)
        return {
          date: x.deliveryDate,
          timeSlots: [{ slot: AvailableSlotName.AM, disabled: true }, { slot: AvailableSlotName.PM }],
        }

      return null
    })

    editDeliveryAvailableDates.value = deliveryScheduleMapped
      .filter((x) => x !== null)
      .sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf())

    appStateStore.updateFooterState(false)
  }, setLoading)

const onConfirmUpdateFutureOrderDelivery = (deliveryDate: string, deliverySlot: string) =>
  loadingWrapper(async () => {
    const orderSubscriptionContractId = editDeliveryDate.value?.order.subscriptionContractId
    const orderCycleIndex = editDeliveryDate.value?.order.cycleIndex

    if (!orderSubscriptionContractId || !orderCycleIndex) return

    const subscriptionContract = subscriptionsStore.userSubContracts?.find((x) => x.id === orderSubscriptionContractId)

    if (!subscriptionContract) return

    const zoneId = subscriptionContract.customAttributes.find((x: any) => x.key === 'zoneId').value

    const deliveryPreference = deliverySlot === 'AM' ? 'am' : 'pm'

    const { error, updates } = await useOrders().updateDelivery(
      deliveryDate,
      deliveryPreference,
      orderSubscriptionContractId,
      orderCycleIndex,
    )

    if (error) {
      return
    } else {
      await ordersStore.load()
      await subscriptionsStore.load()
      editDeliveryDateToggle.value = false
      appStateStore.updateFooterState(true)
    }
  }, setLoading)

const onCancelEditFutureOrderDelivery = (): void => {
  editDeliveryDateToggle.value = false
  appStateStore.updateFooterState(true)
}

const productMeals = computed(() => {
  return productsStore.allProducts?.filter((x) => x?.productType === ProductType.MEAL)
})

const productExtras = computed(() => {
  return productsStore.allProducts?.filter((x) => x?.productType === ProductType.TREAT)
})

const editMealsDogName = computed(
  () => editMealsOrder.value?.petId && (animalsStore.getDogById(editMealsOrder.value?.petId)?.name ?? ''),
)

const toggleEditCancelWarningDialog = () => {
  editCancelWarning.value = !editCancelWarning.value
}

const onClickConfirmCancelWarningDialog = () => {
  toggleEditCancelWarningDialog()
  editMealsToggle.value = false
}
</script>

<template>
  <ion-page>
    <div v-if="editCancelWarning" class="tw-z-10">
      <PawDialogBox
        :open="editCancelWarning"
        has-cancel-btn
        outlined-button-text="Yes"
        class="tw-h-screen"
        @click-close="toggleEditCancelWarningDialog"
        @click-outlined-btn="onClickConfirmCancelWarningDialog"
      >
        <p>Discard changes?</p>
      </PawDialogBox>
    </div>

    <Deliveries
      v-show="!editMealsToggle || !addProductsToggle"
      :loading="ordersStore.loading"
      @edit-future-order-meals="onEditFutureOrderMeals"
      @add-future-order-products="onAddFutureOrderProducts"
      @edit-future-order-products="onEditFutureOrderProducts"
      @edit-future-order-delivery-date="onEditFutureOrderDeliveryDate"
      key="deliveries"
    />

    <TransitionGroup name="slide-in">
      <EditMeals
        v-if="editMealsToggle"
        :line-items="editDogMeals"
        :product-meals="productMeals"
        :dog-name="editMealsDogName"
        :total-pouches="editDogMeals?.reduce((a, b) => a + b.quantity, 0) || 0"
        @cancel-edit-meal="onCancelEditMeals"
        @confirm-edit-meal="onConfirmEditMeals"
        key="edit-meals"
        class="tw-absolute tw-top-0"
      />
    </TransitionGroup>

    <TransitionGroup name="slide-in">
      <AddProducts
        v-if="addProductsToggle"
        :line-items="addDogProducts"
        :product-meals="productExtras"
        :dog-name="editMealsDogName"
        :order="updateProductsOrder"
        @cancel-add-products="onCancelAddProducts"
        @confirm-add-products="onConfirmAddProducts"
        key="add-products"
        class="tw-absolute tw-top-0"
      />
    </TransitionGroup>

    <TransitionGroup name="slide-in">
      <EditProducts
        v-if="editProductsToggle"
        :line-items="editDogProducts"
        :dog-name="editMealsDogName"
        :order="updateProductsOrder"
        @cancel-edit-products="onCancelEditProducts"
        @confirm-edit-products="onConfirmEditProducts"
        @add-products="onAddFutureOrderProductsCloseEditProducts"
        key="edit-products"
        class="tw-absolute tw-top-0"
      />
    </TransitionGroup>

    <SelectDeliveryDateModal
      v-if="editDeliveryDateToggle"
      :loading="loading"
      :delivery-date="editDeliveryDate?.date"
      :delivery-slot="editDeliveryDate?.slot"
      :available-dates="editDeliveryAvailableDates"
      @cancel="onCancelEditFutureOrderDelivery"
      @update="onConfirmUpdateFutureOrderDelivery"
    />
  </ion-page>
</template>

<style scoped>
.slide-in-leave-active,
.slide-in-enter-active {
  @apply max-md:tw-transform tw-translate-x-0 max-md:tw-duration-500;
}

.slide-in-enter-from,
.slide-in-leave-to {
  @apply max-md:tw-transform max-md:tw-translate-x-full;
}
</style>
