import { ServerInferRequest } from '@ts-rest/core'
import { useClient } from '@/composables/useClient'
import { contract } from '@repo/api-contracts'

type ForgotPasswordRequest = ServerInferRequest<typeof contract.auth.forgotPassword>

export const useForgotPassword = async ({ email }: ForgotPasswordRequest['body']) => {
  const client = await useClient()
  const { status, body } = await client.auth.forgotPassword({
    body: {
      email,
    },
  })

  let success = false
  let error = null
  if (status === 200) {
    success = true
  } else if (status === 401) {
    error = body.error
  }

  return { error, success }
}
