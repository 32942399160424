import { ServerInferRequest } from '@ts-rest/core'
import { useClient } from '@/composables/useClient'
import { contract } from '@repo/api-contracts'
import { useAuthStore } from '@/stores/auth'

type RefreshBodyRequest = ServerInferRequest<typeof contract.auth.refresh>

export const useRefreshToken = async () => {
  const authStore = useAuthStore()
  const client = await useClient()
  const { status, body } = await client.auth.refresh()

  let success = false
  let error = null
  if (status === 200) {
    success = true
    // Set new access token
    authStore.setAccessToken(body.accessToken)
  } else if (status === 400) {
    error = body.error
  }

  return { error, success }
}
