import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import { ErrorResponseSchema } from './general'

const c = initContract()

export const authContract = c.router({
  login: {
    method: 'POST',
    path: '/login',
    responses: {
      200: z.object({ accessToken: z.string(), customerToken: z.string() }),
      401: ErrorResponseSchema,
    },
    body: z.object({
      email: z.string(),
      password: z.string(),
    }),
  },
  forgotPassword: {
    method: 'POST',
    path: '/forgot-password',
    responses: {
      200: z.object({ success: z.boolean() }),
      401: ErrorResponseSchema,
    },
    body: z.object({
      email: z.string(),
    }),
  },
  confirmForgotPassword: {
    method: 'POST',
    path: '/confirm-forgot-password',
    responses: {
      200: z.object({ success: z.boolean() }),
      401: ErrorResponseSchema,
    },
    body: z.object({
      email: z.string(),
      password: z.string(),
      confirmationCode: z.string(),
    }),
  },
  resetPassword: {
    method: 'POST',
    path: '/reset-password',
    responses: {
      200: z.object({ success: z.boolean() }),
      500: ErrorResponseSchema,
    },
    body: z.object({
      previousPassword: z.string(),
      password: z.string(),
    }),
  },
  refresh: {
    method: 'GET',
    path: '/refresh',
    responses: {
      200: z.object({ accessToken: z.string() }),
      400: ErrorResponseSchema,
    },
  },
})
