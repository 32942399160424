export enum OrderStatus {
  UPNEXT = 'UP NEXT',
  PAST = 'PAST',
  PREPARING = 'PREPARING',
  FUTURE = 'FUTURE',
}

export enum DeliverySlot {
  AM = '12AM-7AM',
  PM = '8AM-6PM',
}
