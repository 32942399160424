<script setup lang="ts">
import ViewLayout from '@/components/ViewLayout.vue'
import { withDefaults, ref, onBeforeMount, computed } from 'vue'
import type { LineItem, Product, BillingCycleOrder } from '@repo/api-types'
import { PawHeading, PawDivider } from '@lyka-pet-food/lyka-common'
import EditProductItem from '@/components/EditProductItem.vue'
import StickyBottomEditProduct from '@/components/stickyNav/StickyBottomEditProduct.vue'
import { useProductsStore } from '@/stores/products'
import { useSubscriptionsStore } from '@/stores/subscriptions'
import { dateToLocalString } from '@/utils/dateToLocalString'

const props = withDefaults(
  defineProps<{
    lineItems?: LineItem[]
    order?: BillingCycleOrder
  }>(),
  {},
)

const emits = defineEmits<{
  (e: 'cancelEditProducts'): void
  (e: 'confirmEditProducts', updatedProducts: Array<LineItem>): void
  (e: 'addProducts', lineItems: LineItem[] | undefined, order: BillingCycleOrder | undefined): void
}>()

const editProducts = ref<Array<LineItem>>([])

const subscriptionsStore = useSubscriptionsStore()

const subscriptionContract = subscriptionsStore.userSubContracts?.find(
  (x) => x.id === props.order?.subscriptionContractId,
)

const onUpdateQuantity = (index: number, quantity: number): void => {
  editProducts.value[index].quantity = quantity
}

const totalMealsQuantity = computed(() => {
  return editProducts.value?.reduce((a, b) => a + b.quantity, 0) || 0
})

const totalPrice = computed(() => {
  return editProducts.value?.reduce((a, b) => a + b.price * b.quantity, 0)
})

const updatedProducts = computed(() => {
  return editProducts.value
})

const deliveryFrequency = computed(() => {
  const frequency = subscriptionContract?.billingDeliveryRecurringIntervalValue
  if (!frequency) return ''
  return frequency > 1 ? `${frequency} weeks` : 'week'
})

onBeforeMount(() => {
  editProducts.value =
    props.lineItems?.map((item) => {
      return { ...item }
    }) || []
})
</script>

<template>
  <ViewLayout>
    <div class="tw-max-w-[1140px] tw-space-y-8">
      <div class="tw-flex tw-items-start tw-flex-row tw-self-start">
        <img
          src="@/assets/icons/chevron-left.svg"
          class="tw-pr-4 tw-cursor-pointer md:tw-pt-1"
          @click="emits('cancelEditProducts')"
        />
        <div class="tw-space-y-1">
          <PawHeading size="3xl">Edit extras for {{ dateToLocalString(props.order?.expectedDeliveryDate) }}</PawHeading>
          <div class="tw-text-body-md tw-text-content-accent">View all your extras</div>
        </div>
      </div>

      <div class="tw-w-full">
        <div
          class="tw-p-4 min-[1440px]:tw-p-8 tw-rounded-3xl -tw-mx-4 md:tw-mx-0 tw-mb-52 md:tw-w-full md:tw-grid md:tw-grid-cols-3 xl:tw-grid-cols-4 md:tw-gap-8"
        >
          <div v-for="(product, index) in editProducts" :key="`meal-item-${index}`">
            <EditProductItem
              :line-item="product"
              :delivery-frequency="deliveryFrequency"
              @update-quantity="onUpdateQuantity(index, $event)"
            />
            <PawDivider class="tw-my-4 md:tw-hidden" />
          </div>

          <div
            class="tw-flex tw-text-center tw-items-center md:tw-justify-center tw-space-x-4 md:tw-space-x-2 tw-cursor-pointer tw-border tw-border-primary tw-border-dashed tw-rounded-xl tw-p-2 md:tw-flex-col md:tw-space-y-2 md:tw-w-[229px] md:tw-h-[164px]"
            @click="emits('addProducts', props.lineItems, props.order)"
          >
            <div
              class="tw-min-w-16 tw-min-h-16 md:tw-min-w-[52px] md:tw-min-h-[52px] tw-rounded-lg tw-bg-surface-brand-secondary tw-flex tw-justify-center tw-items-center"
            >
              <img src="@/assets/images/deliveries/plus-icon.svg" />
            </div>
            <div class="tw-text-body-md md:tw-text-body-lg">Add extras to this delivery</div>
          </div>

          <div class="tw-text-body-lg tw-text-center tw-mt-12 md:tw-hidden">
            Total: <span class="tw-font-bold">${{ totalPrice.toFixed(2) }}</span>
          </div>
        </div>
      </div>
    </div>

    <template #postView>
      <StickyBottomEditProduct
        :total-quantity="totalMealsQuantity"
        :total-price="totalPrice"
        @cancel="emits('cancelEditProducts')"
        @confirm="emits('confirmEditProducts', updatedProducts)"
      />
    </template>
  </ViewLayout>
</template>
