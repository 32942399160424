import { z } from 'zod'
import { SERVING_SIZE_OPTIONS } from '@lyka-pet-food/lyka-shared-types/lib/types/ProductService'

export const UpdateSubscriptionPlanBodySchema = z.object({
  mealPlanTemplateId: z.string(),
  mealPlanTemplateName: z.string(),
  totalPouches: z.number().gte(0),
  pouchSizeInGrams: z.number().gte(0),
  dailyServingSizeInGrams: z.number().gte(0),
  recommendedDailyServingSizeInGrams: z.number().gte(0),
  deliveryFrequencyWeeks: z.number().gte(0),
})

export type UpdateSubscriptionPlanBodySchemaValues = z.infer<typeof UpdateSubscriptionPlanBodySchema>

export const UpdateFeedingPlanBodySchema = z.object({
  petId: z.string(),
  feedingPlanType: z.string(),
  servingSize: z.enum(SERVING_SIZE_OPTIONS),
})
export type UpdateFeedingPlanBodySchemaValues = z.infer<typeof UpdateFeedingPlanBodySchema>
