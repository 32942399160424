<script setup lang="ts"></script>
<template>
  <div class="tw-absolute tw-h-screen tw-w-screen tw-z-40 tw-top-0 tw-bg-black/25">
    <div
      class="tw-shrink-transition tw-absolute max-md:tw-bottom-0 tw-w-screen tw-rounded-t-3xl md:tw-rounded-b-3xl tw-z-50 tw-bg-surface-contrast tw-py-12 tw-px-8 md:tw-px-6 tw-text-center tw-text-body-lg md:tw-top-1/2 md:-tw-translate-y-[56%] md:tw-w-[494px] md:tw-left-1/2 md:-tw-translate-x-1/2"
    >
      <slot />
    </div>
  </div>
</template>
