import { z } from 'zod'

export type { DeliverySchedulesResponseSchema } from '@lyka-pet-food/lyka-shared-types/lib/types/CourierService'

export enum SlotAvailability {
  None = 0,
  AmAvailable = 1 << 0,
  PmAvailable = 1 << 1,
  BothAvailable = (1 << 0) | (1 << 1),
}

export const CourierDeliveryScheduleSchema = z.object({
  deliveryDate: z.string().date(),
  slotAvailability: z.nativeEnum(SlotAvailability),
})

export type CourierDeliverySchedule = z.infer<typeof CourierDeliveryScheduleSchema>
