<script setup lang="ts">
import { PawButtonOutlined, PawButtonFilled, IconSpinner } from '@lyka-pet-food/lyka-common'
import StickyBottomNavMessage from '@/components/stickyNav/StickyBottomNavMessage.vue'

const props = withDefaults(
  defineProps<{
    loading?: boolean
    confirmDisabled?: boolean
    confirmText?: string
  }>(),
  {
    loading: false,
    confirmDisabled: false,
    confirmText: 'Confirm',
  },
)

const emits = defineEmits<{
  (e: 'cancel'): void
  (e: 'confirm'): void
}>()
</script>

<template>
  <div
    class="tw-fixed tw-z-50 tw-bottom-0 tw-w-full tw-space-y-4 tw-rounded-t-3xl tw-bg-surface-light tw-p-4 tw-pb-4 tw-shadow-[2px_0px_10px_3px_rgba(0,0,0,0.05)] md:tw-rounded-none lg:tw-flex lg:tw-justify-center"
  >
    <div class="tw-flex tw-w-full tw-max-w-[1076px] tw-justify-between tw-space-x-4">
      <PawButtonOutlined class="tw-w-1/2 tw-border-solid md:tw-w-[264px]" @click="emits('cancel')" name="Cancel"
        >Cancel</PawButtonOutlined
      >
      <PawButtonFilled
        class="tw-w-1/2 md:tw-w-[264px]"
        :disabled="confirmDisabled"
        @click="emits('confirm')"
        name="Confirm"
      >
        <span v-if="!loading">{{ confirmText }}</span>
        <IconSpinner v-if="loading" class="" style="width: 32px; height: 32px" />
      </PawButtonFilled>
    </div>
  </div>
</template>
