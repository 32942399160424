<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    actionType: 'increment' | 'decrement'
  }>(),
  {},
)
const emits = defineEmits<{
  (e: 'updateValue', value: number): void
}>()
const method = {
  handleClick() {
    if (props.actionType === 'increment') {
      emits('updateValue', 1)
    }
    if (props.actionType === 'decrement') {
      emits('updateValue', -1)
    }
  },
}
</script>

<template>
  <div
    class="tw-m-0 tw-grid tw-h-[48px] tw-w-[48px] tw-cursor-pointer tw-place-items-center tw-rounded-full tw-p-0 tw-accent-border-secondary tw-transition-all tw-duration-300 hover:tw-bg-surface-brand-secondary"
    @click="method.handleClick"
  >
    <img v-if="actionType === 'increment'" src="@/assets/icons/increase.svg" alt="increase" class="tw-w-[48px]" />
    <img v-if="actionType === 'decrement'" src="@/assets/icons/decrease.svg" alt="decrease" class="tw-w-[48px]" />
  </div>
</template>
