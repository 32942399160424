import { ServerInferRequest } from '@ts-rest/core'
import { useClient } from '@/composables/useClient'
import { contract } from '@repo/api-contracts'

type ConfirmForgotPasswordRequest = ServerInferRequest<typeof contract.auth.confirmForgotPassword>

export const useConfirmForgotPassword = async ({
  email,
  password,
  confirmationCode,
}: ConfirmForgotPasswordRequest['body']) => {
  const client = await useClient()
  const { status, body } = await client.auth.confirmForgotPassword({
    body: {
      email,
      password,
      confirmationCode,
    },
  })

  let success = false
  let error = null
  if (status === 200) {
    success = true
  } else if (status === 401) {
    error = body.error
  }

  return { error, success }
}
