<script setup lang="ts">
import { PawHeading, IconSpinner } from '@lyka-pet-food/lyka-common'
import { computed } from 'vue'

const props = defineProps({
  loading: Boolean,
})

const loadingState = computed(() => props.loading)
</script>
<template>
  <div
    class="tw-absolute tw-z-[200] tw-h-screen tw-w-screen tw-flex tw-justify-center tw-items-center tw-flex-col tw-space-y-6 tw-bg-surface-light"
  >
    <IconSpinner v-if="loadingState" class="" style="width: 48px; height: 48px" />
    <img v-if="!loadingState" src="@/assets/icons/success-tick.png" class="tw-w-16 tw-h-auto" />
    <PawHeading v-if="!loadingState" size="4xl">Successfully updated</PawHeading>
  </div>
</template>
