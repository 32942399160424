<script setup lang="ts">
import { PawDivider, PawButtonOutlined, PawButtonFilled } from '@lyka-pet-food/lyka-common'
import StickyBottomNavMessage from '@/components/stickyNav/StickyBottomNavMessage.vue'
import { computed } from 'vue'

const props = defineProps<{
  selectedQuantity: number
  totalQuantity: number
}>()

const emits = defineEmits<{
  (e: 'cancel'): void
  (e: 'confirm'): void
}>()

const confirmButtonDisabled = computed(() => props.selectedQuantity !== props.totalQuantity)
</script>

<template>
  <div
    class="tw-w-full tw-bg-surface-light tw-p-4 tw-rounded-t-3xl md:tw-rounded-none tw-space-y-4 tw-pb-4 tw-fixed tw-bottom-0 tw-shadow-[2px_0px_10px_3px_rgba(0,0,0,0.05)] lg:tw-flex lg:tw-justify-center"
  >
    <StickyBottomNavMessage
      class="md:tw-hidden"
      :selected-quantity="selectedQuantity"
      :total-quantity="totalQuantity"
    />

    <PawDivider class="md:tw-hidden" />

    <div class="tw-flex tw-justify-between tw-space-x-4 tw-max-w-[1076px] tw-w-full">
      <PawButtonOutlined class="tw-border-solid tw-w-1/2 md:tw-w-[264px]" @click="emits('cancel')" name="Cancel"
        >Cancel</PawButtonOutlined
      >
      <StickyBottomNavMessage
        class="tw-hidden md:tw-flex"
        :selected-quantity="selectedQuantity"
        :total-quantity="totalQuantity"
      />
      <PawButtonFilled
        class="tw-w-1/2 md:tw-w-[264px]"
        :disabled="confirmButtonDisabled"
        @click="emits('confirm')"
        name="Confirm"
        >Confirm</PawButtonFilled
      >
    </div>
  </div>
</template>
