import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import { useSegment } from '@/composables/useSegment'

import { useAuthStore } from '@/stores/auth'
import { useAnimalsStore } from '@/stores/animals'
import { useOrdersStore } from '@/stores/orders'
import { useProductsStore } from '@/stores/products'
import { useSubscriptionsStore } from '@/stores/subscriptions'
import { useAppStateStore } from '@/stores/appState'

import Login from '@/views/containers/Login.container.vue'
import Deliveries from '@/views/containers/Deliveries.container.vue'
import Dog from '@/views//containers/Dog.container.vue'
import Shop from '@/views/containers/Shop.container.vue'
import Account from '@/views/containers/Account.container.vue'
import ForgotPassword from '@/views/containers/ForgotPassword.container.vue'
import ForgotPasswordEmailSent from '@/views/ForgotPasswordEmailSent.vue'
import EditServingSizeContainer from '@/views/containers/EditServingSizeFlow.container.vue'
import ConfirmForgotPassword from '@/views/containers/ConfirmForgotPassword.container.vue'

// Route names for access without authentication
const UNAUTHED_ROUTE_NAMES = ['login', 'forgot-password', 'email-sent', 'confirm-forgot-password']

function updateAllStores() {
  useAnimalsStore().load()
  useOrdersStore().load()
  useProductsStore().load()
  useSubscriptionsStore().load()
}

export async function loadAllStores() {
  useAppStateStore().updateLoadingState(true)
  await Promise.all([
    useAnimalsStore().load(),
    useOrdersStore().load(),
    useSubscriptionsStore().load(),
    useProductsStore().load(),
  ])
  useAppStateStore().updateLoadingState(false)
}

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/deliveries',
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/confirm-forgot-password',
    name: 'confirm-forgot-password',
    component: ConfirmForgotPassword,

    props: (route) => {
      return {
        confirmationCode: route.query.code,
        email: route.query.email,
      }
    },
  },
  {
    path: '/email-sent',
    name: 'email-sent',
    component: ForgotPasswordEmailSent,
  },
  {
    path: '/deliveries',
    name: 'deliveries',
    component: Deliveries,
    beforeEnter: (to, from) => {
      if (from.name !== 'login') {
        updateAllStores()
      }
    },
  },
  {
    path: '/dogs',
    name: 'dogs',
    component: Dog,
    beforeEnter: (to, from) => {
      if (from.name !== 'login') {
        updateAllStores()
      }
    },
  },
  {
    path: '/shop',
    name: 'shop',
    component: Shop,
  },
  {
    path: '/account',
    name: 'account',
    component: Account,
  },
  {
    path: '/plans/:subscriptionId/:contractId/edit-serving-size',
    name: 'edit-serving-size',
    component: EditServingSizeContainer,
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL),
  routes,
})

router.beforeEach(async (to, from) => {
  const authStore = useAuthStore()
  await authStore.checkTokenIsExpired()
  const appStateStore = useAppStateStore()

  if (authStore.isAuthenticated && !appStateStore.preloaded) {
    await loadAllStores()
    appStateStore.updatePreloadState(true)
  }

  if (authStore.isAuthenticated && to.name === 'login') {
    return { name: 'deliveries' }
  }

  if (!authStore.isAuthenticated && to.name !== 'login' && !UNAUTHED_ROUTE_NAMES.includes(to.name as string)) {
    return { name: 'login' }
  }
})

router.afterEach(async (to, from) => {
  useSegment().track('Viewed Page', { page: to.name as string })
})

export default router
