<script setup lang="ts">
import { ref, computed } from 'vue'
import { PawButtonOutlined, PawButtonFilled, PawHeading, PawDatePicker, IconSpinner } from '@lyka-pet-food/lyka-common'
import { dateToLocalDateFull, dateToLocalString } from '@/utils/dateToLocalString'
import EditDeliveryDateConfirmModal from './EditDeliveryDateConfirmModal.vue'
import StickyBottomModalWrapper from './stickyBottomModalWrapper.vue'
import type { AvailableDates } from '@/types/delivery'

const props = withDefaults(
  defineProps<{
    deliveryDate?: string
    deliverySlot?: string
    availableDates?: AvailableDates[]
    updateSuccess?: boolean
    loading?: boolean
  }>(),
  {
    updateSuccess: false,
  },
)

const emits = defineEmits<{
  (e: 'cancel'): void
  (e: 'update', deliveryDate: string, deliverySlot: string): void
}>()

const selectedDate = ref(props.deliveryDate && new Date(props.deliveryDate).toLocaleString('sv').substring(0, 10))
const selectedSlot = ref(props.deliverySlot === 'AM' ? '12am - 7am' : '8am - 6pm')
const confirmed = ref(false)

const selectedSlotAmPm = computed(() => (selectedSlot.value === '8am - 6pm' ? 'PM' : 'AM'))

const onSelectDate = (date: string): void => {
  selectedDate.value = date

  const availableSlots = props.availableDates?.find((x) => x.date === date)?.timeSlots
  const preferredSlot = availableSlots?.findIndex(
    (x) => x.slot === (props.deliverySlot === 'AM' ? '12am - 7am' : '8am - 6pm'),
  )

  if (!availableSlots || preferredSlot === undefined || preferredSlot === -1) {
    return
  }

  if (!availableSlots[preferredSlot].disabled) {
    selectedSlot.value = availableSlots[preferredSlot].slot
  } else if (!availableSlots[(preferredSlot + 1) % 2].disabled) {
    selectedSlot.value = availableSlots[(preferredSlot + 1) % 2].slot
  }
}

const onSelectSlot = (slot: string): void => {
  selectedSlot.value = slot
}

const onClickNext = (): void => {
  confirmed.value = true
}

const onClickCancelConfirm = (): void => {
  confirmed.value = false
}

const pickerReady = computed(() => props.availableDates && props.availableDates.length)
</script>

<template>
  <StickyBottomModalWrapper>
    <div v-if="!confirmed && !props.updateSuccess" class="tw-space-y-8">
      <img
        src="@/assets/icons/x-mark.svg"
        class="tw-absolute tw-right-6 tw-top-6 tw-cursor-pointer"
        @click="emits('cancel')"
      />

      <div>
        <PawHeading size="2xl">Edit delivery date</PawHeading>
        <div data-testid="test-delivery-date" class="tw-text-body-md">
          {{ selectedDate && dateToLocalDateFull(selectedDate) }}
          <span class="tw-uppercase">{{ selectedSlot }}</span>
        </div>
      </div>

      <div data-testid="test-date-picker" class="tw-flex tw-w-full tw-justify-center">
        <PawDatePicker
          v-model="selectedDate"
          future
          has-delivery-slots
          :available-dates="props.availableDates"
          :selected-date="selectedDate"
          :delivery-input="selectedSlot"
          @update="onSelectDate"
          @update:delivery="onSelectSlot"
          class="tw-bg-transparent tw-max-w-full tw-w-full"
        />
      </div>

      <div
        class="tw-flex tw-w-full tw-justify-between md:tw-items-center tw-space-x-4 md:tw-space-x-0 md:tw-flex-col md:tw-space-y-4"
      >
        <PawButtonOutlined
          data-testid="test-back-button"
          class="tw-border-solid md:tw-max-w-80"
          block
          @click="emits('cancel')"
          >Back
        </PawButtonOutlined>
        <PawButtonFilled
          data-testid="test-next-button"
          block
          class="tw-flex tw-text-center md:tw-max-w-80"
          @click="onClickNext"
          :disabled="props.loading"
        >
          <span v-if="!props.loading">Next</span>
          <span class="tw-flex tw-flex-row tw-items-center tw-justify-center">
            <IconSpinner v-if="props.loading" class="" style="width: 32px; height: 32px" />
          </span>
        </PawButtonFilled>
      </div>
    </div>

    <EditDeliveryDateConfirmModal
      v-if="confirmed && !props.updateSuccess && props.deliveryDate && selectedDate"
      :loading="props.loading"
      :oldDeliveryDate="props.deliveryDate"
      :newDeliveryDate="selectedDate"
      :deliverySlot="selectedSlotAmPm"
      @cancel="onClickCancelConfirm"
      @confirm="emits('update', selectedDate, selectedSlotAmPm)"
    />

    <div v-if="props.updateSuccess" class="tw-flex tw-justify-center tw-items-center tw-flex-col tw-space-y-6">
      <img src="@/assets/icons/success-tick.png" class="tw-w-16 tw-h-auto" />
      <PawHeading size="4xl">Delivery date successfully updated</PawHeading>
    </div>
  </StickyBottomModalWrapper>
</template>
