<script setup lang="ts">
import { ref, watch } from 'vue'
import { IonToast } from '@ionic/vue'
import { PawButtonFilled, PawHeading, PawTextFieldPassword, PawFormGroup } from '@lyka-pet-food/lyka-common'
import { useResetPassword } from '@/composables/useResetPassword'
import ToastSuccess from '@/assets/icons/success.svg'
import Error from '@/components/Error.vue'

const password = ref('')
const previousPassword = ref('')
const confirmPassword = ref('')
const passwordError = ref('')
const genericError = ref(false)
const otherError = ref('')
const resetSuccess = ref(false)

const confirmPasswordsMatch = (): boolean => {
  if (password.value !== confirmPassword.value) {
    otherError.value = "Passwords don't match."
    return false
  }
  return true
}

const confirmPasswordLength = (): boolean => {
  if (password.value.length >= 8) {
    return true
  }
  passwordError.value = 'Password must be 8 characters or longer.'
  return false
}

const reset = async (): Promise<void> => {
  // Reset any existing errors
  genericError.value = false
  otherError.value = ''

  if (!confirmPasswordsMatch() || !confirmPasswordLength()) {
    return
  }

  const { error, success } = await useResetPassword({
    password: password.value,
    previousPassword: previousPassword.value,
  })
  if (error) {
    otherError.value = error
    return
  }

  resetSuccess.value = success
  password.value = ''
  confirmPassword.value = ''
}

watch(password, async () => {
  if (password.value !== '' && confirmPasswordLength()) {
    passwordError.value = ''
  }
})
</script>

<template>
  <div class="tw-flex tw-flex-col tw-space-y-6 tw-w-full md:tw-w-[400px]">
    <div class="tw-text-center tw-space-y-2">
      <PawHeading size="xl" class="!tw-font-primary tw-font-bold">Reset password</PawHeading>
      <div>Enter and confirm your new password.</div>
    </div>
    <form id="reset-form" @submit.prevent="reset">
      <PawFormGroup class="tw-space-y-6">
        <PawTextFieldPassword
          name="previous-password"
          v-model="previousPassword"
          :required="true"
          label="Current password"
          placeholder="Current password"
        />
        <PawTextFieldPassword
          name="reset-password"
          :error="passwordError"
          v-model="password"
          :required="true"
          label="New password"
          placeholder="New password"
        />
        <PawTextFieldPassword
          name="confirm-password"
          v-model="confirmPassword"
          :required="true"
          label="Confirm password"
          placeholder="Confirm password"
        />
        <PawButtonFilled type="submit" form="reset-form" variant="invert" class="tw-w-full"
          >Reset password</PawButtonFilled
        >
      </PawFormGroup>
    </form>

    <Error v-if="genericError">
      Password change unsccessful. Try again or contact customer care at
      <a class="tw-cursor-pointer tw-underline" href="mailto:info@lyka.com.au">info@lyka.com.au</a> or 1300 851 936.
    </Error>
    <Error v-else-if="otherError">{{ otherError }}</Error>
  </div>

  <ion-toast
    position="top"
    :is-open="resetSuccess"
    message="Successfully reset password"
    :icon="ToastSuccess"
    :duration="1000"
    @didDismiss="resetSuccess = false"
    class="success-toast"
  ></ion-toast>
</template>
