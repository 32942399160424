<script setup lang="ts">
import { ref, onMounted } from 'vue'
import type { Order, BillingCycleOrder } from '@repo/api-types'
import CalendarItem from './CalendarItem.vue'

const props = withDefaults(
  defineProps<{
    deliveryDetails: {
      status: string
      date: string
    }[]
    defaultIndex?: number
  }>(),
  {
    defaultIndex: 0,
  },
)

const emit = defineEmits<{
  (e: 'scrollToItem', index: number): void
}>()

const activeIndex = ref(props.defaultIndex)

const scrollToItem = (
  index: number,
  behavior: ScrollBehavior = 'smooth',
  inline: ScrollLogicalPosition = 'nearest',
) => {
  activeIndex.value = index
  const item = document.getElementById('tab-' + index)
  item?.scrollIntoView({ behavior: behavior, inline: inline, block: 'nearest' })
  emit('scrollToItem', index)
}

onMounted(() => {
  scrollToItem(activeIndex.value, 'instant', 'center')
})
</script>

<template>
  <div class="tw-w-full tw-flex tw-justify-center">
    <div
      class="tw-flex tw-scroll-mx-12 tw-items-center tw-space-x-3 tw-cursor-pointer tw-overflow-x-scroll tw-no-scrollbar tw-min-h-[122px] lg:tw-h-[152px]"
    >
      <div
        :class="index === 0 ? 'tw-pl-4' : index === deliveryDetails.length - 1 ? 'tw-pr-4' : ''"
        v-for="(item, index) in deliveryDetails"
        :key="index"
        :id="'tab-' + index"
        @click="scrollToItem(index)"
      >
        <CalendarItem :orderStatus="item.status" :date="item.date" :active="activeIndex === index" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.tw-no-scrollbar::-webkit-scrollbar {
  display: none;
}
.tw-no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
