<script setup lang="ts">
import { computed } from 'vue'
import { pluralize } from '@/utils/pluralize'

const props = defineProps<{
  selectedQuantity: number
  totalQuantity: number
}>()

const selectedQuantityDiff = computed(() => props.selectedQuantity - props.totalQuantity)
</script>

<template>
  <div class="tw-flex tw-justify-between tw-items-center md:tw-space-x-4">
    <div data-testid="test-nav-hint">
      <span class="tw-text-content-danger" v-if="selectedQuantityDiff > 0"
        ><span class="tw-font-bold">Remove</span> {{ selectedQuantityDiff }}
        {{ pluralize(selectedQuantityDiff, 'pouch', 'es') }}</span
      >
      <span class="tw-text-content-accent" v-else-if="selectedQuantityDiff < 0"
        ><span class="tw-font-bold">Add</span> {{ -selectedQuantityDiff }}
        {{ pluralize(-selectedQuantityDiff, 'pouch', 'es') }}</span
      >
      <span v-else
        ><img src="@/assets/images/deliveries/selected-icon.png" class="tw-inline tw-pb-1 tw-pr-1.5 tw-w-[25px]" />Just
        right!</span
      >
    </div>
    <div class="tw-rounded-full tw-bg-surface-brand tw-text-content-invert tw-px-3 tw-py-1">
      {{ selectedQuantity }}/{{ totalQuantity }}
    </div>
  </div>
</template>
