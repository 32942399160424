<script setup lang="ts">
import { PawQuantitySelector } from '@lyka-pet-food/lyka-common'
import { defineProps, ref, defineEmits, computed } from 'vue'
import { useProductsStore } from '@/stores/products'
import { productNameToTitle } from '@/utils/productNameToTitle'
import { LineItem, BillingCycleOrder } from '@repo/api-types'

const props = withDefaults(
  defineProps<{
    lineItem: LineItem
    deliveryFrequency?: string
  }>(),
  {},
)

const mealsQuantity = ref(props.lineItem.quantity)
const productsStore = useProductsStore()

const product = productsStore.getProductByProductVariantId(props.lineItem.productVariantId)
const productVariant = productsStore.getProductVariantById(props.lineItem.productVariantId)

const productWeight = computed(() => {
  const productWeightMeta = productVariant?.productVariantMeta?.find((x) => x.metaKey === 'packageWeight')
  return productWeightMeta?.metaValue ?? ''
})

const emits = defineEmits<{
  (e: 'updateQuantity', quantity: number): void
  (e: 'viewMealsDetail'): void
}>()

const onClickAddMeals = (): void => {
  mealsQuantity.value++
  emits('updateQuantity', mealsQuantity.value)
}

const onClickRemoveMeals = (): void => {
  if (mealsQuantity.value > 0) {
    mealsQuantity.value--
    emits('updateQuantity', mealsQuantity.value)
  }
}

const onClickViewMealsDetail = (): void => {
  emits('viewMealsDetail')
}

const onUpdateQuantity = (quantity: number | undefined): void => {
  if (!quantity) return

  mealsQuantity.value = Number(quantity)
  emits('updateQuantity', mealsQuantity.value)
}
</script>

<template>
  <div class="tw-w-full tw-flex md:tw-flex-col md:tw-space-y-4 tw-space-x-5 md:tw-space-x-0">
    <div
      class="tw-w-1/3 max-[360px]:tw-h-[85px] md:tw-w-[229px] tw-h-[115px] md:tw-h-[164px] tw-bg-surface-brand-secondary tw-overflow-hidden tw-rounded-xl tw-flex tw-items-center tw-justify-center"
      @click="onClickViewMealsDetail"
    >
      <div class="tw-w-4/5 tw-h-auto tw-relative">
        <img :src="product?.imageUrl" class="tw-w-full tw-h-auto tw-scale-[1.3] md:tw-scale-100" />
      </div>
    </div>

    <div class="tw-flex tw-flex-col tw-w-2/3 md:tw-w-full tw-space-y-4">
      <div class="tw-space-y-0.5">
        <div class="tw-text-body-lg">{{ productNameToTitle(product?.displayName ?? '') }}</div>
        <div>{{ productWeight }}</div>
        <div v-if="!props.lineItem.oneOff" class="tw-flex">
          <img src="@/assets/icons/repeat-brand-green.svg" class="tw-mr-2" />every {{ deliveryFrequency }}
        </div>
      </div>

      <div class="tw-w-[165px] tw-pt-2 md:tw-pt-0">
        <PawQuantitySelector
          data-testid="quantity-selector"
          v-model="mealsQuantity"
          @click-plus="onClickAddMeals"
          @click-minus="onClickRemoveMeals"
          @update:model-value="onUpdateQuantity"
          :disable-minus="mealsQuantity === 0"
          :disable-plus="mealsQuantity >= 100"
        />
      </div>
    </div>
  </div>
</template>
