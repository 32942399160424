<script setup lang="ts">
import { RetrieveDogReturnDto } from '@repo/api-types/src/entities'
import { PawHeading, PawButtonOutlined } from '@lyka-pet-food/lyka-common'
import { useSubscriptionsStore } from '@/stores/subscriptions'
import { computed } from 'vue'

const props = defineProps<{
  dog: RetrieveDogReturnDto
}>()

const subscriptionsStore = useSubscriptionsStore()

const subscriptionPlan = computed(() => subscriptionsStore.getSubscriptionByPetId(props.dog?.identifier as string))

const subscriptionContract = computed(() =>
  subscriptionsStore.getSubscriptionContractByPetId(props.dog?.identifier as string),
)

const dogMealPlan = computed(() => subscriptionPlan.value?.mealPlanTemplateName)

const mealPlanFrequency = computed(() => subscriptionContract.value?.billingDeliveryRecurringIntervalValue)

const emit = defineEmits<{
  (e: 'editMealPlan', dog: RetrieveDogReturnDto): void
}>()
</script>

<template>
  <div class="tw-w-full tw-flex tw-justify-between tw-mb-4">
    <PawHeading size="2xl" type="subheading">Plan</PawHeading>
    <div class="">
      <PawButtonOutlined class="tw-h-8 tw-border-solid" @click="emit('editMealPlan', dog)">Edit</PawButtonOutlined>
    </div>
  </div>

  <div class="tw-space-y-1">
    <div>{{ dogMealPlan }}</div>
    <div>
      ${{ subscriptionContract?.totalPrice.toFixed(2) }} for {{ mealPlanFrequency }} week{{
        mealPlanFrequency && mealPlanFrequency > 1 ? 's' : ''
      }}
      of food
    </div>
  </div>
</template>
