<script setup lang="ts">
import { ref, watch } from 'vue'
import { IonToast, IonContent, IonPage } from '@ionic/vue'
import { PawButtonFilled, PawHeading, PawTextFieldPassword, PawFormGroup } from '@lyka-pet-food/lyka-common'
import ToastSuccess from '@/assets/icons/success.svg'
import Error from '@/components/Error.vue'
import { useRouter } from 'vue-router'

const props = defineProps({
  loading: Boolean,
  success: Boolean,
})

const emit = defineEmits<{
  (e: 'submit', password: string): void
}>()

const router = useRouter()
const password = ref('')
const confirmPassword = ref('')
const passwordError = ref('')
const genericError = ref(false)
const otherError = ref('')

const submit = () => {
  emit('submit', password.value)
}

const confirmPasswordsMatch = (): boolean => {
  if (password.value !== confirmPassword.value) {
    otherError.value = "Passwords don't match."
    return false
  }
  return true
}

const confirmPasswordLength = (): boolean => {
  if (password.value.length >= 8) {
    return true
  }
  passwordError.value = 'Password must be 8 characters or longer.'
  return false
}

const reset = async (): Promise<void> => {
  // Reset any existing errors
  genericError.value = false
  otherError.value = ''

  if (!confirmPasswordsMatch() || !confirmPasswordLength()) {
    return
  }

  emit('submit', password.value)
}

const goToLogin = () => {
  router.push({
    name: 'login',
  })
}

watch(password, async () => {
  if (password.value !== '' && confirmPasswordLength()) {
    passwordError.value = ''
  }
})
</script>

<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="tw-h-full tw-flex tw-justify-center tw-items-center tw-px-4">
        <div class="tw-flex tw-flex-col tw-space-y-6 tw-w-full md:tw-w-[400px]">
          <div v-if="!props.success">
            <div class="tw-text-center tw-space-y-2">
              <PawHeading size="xl" class="!tw-font-primary tw-font-bold">Reset password</PawHeading>
              <div>Enter and confirm your new password.</div>
            </div>
            <form id="reset-form" @submit.prevent="reset">
              <PawFormGroup class="tw-space-y-6">
                <PawTextFieldPassword
                  name="reset-password"
                  v-if="!props.success"
                  :error="passwordError"
                  v-model="password"
                  :required="true"
                  label="New password"
                  placeholder="New password"
                />
                <PawTextFieldPassword
                  name="confirm-password"
                  v-if="!props.success"
                  v-model="confirmPassword"
                  :required="true"
                  label="Confirm password"
                  placeholder="Confirm password"
                />
                <PawButtonFilled
                  :loading="loading"
                  type="submit"
                  @submit="submit"
                  form="reset-form"
                  variant="invert"
                  class="tw-w-full"
                  >Reset password</PawButtonFilled
                >
              </PawFormGroup>
            </form>

            <Error v-if="genericError">
              Password change unsuccessful. Try again or contact customer care at
              <a class="tw-cursor-pointer tw-underline" href="mailto:info@lyka.com.au">info@lyka.com.au</a> or 1300 851
              936.
            </Error>
            <Error v-else-if="otherError">{{ otherError }}</Error>
          </div>
          <div v-else>
            <PawButtonFilled @click="goToLogin" type="button" variant="invert" class="tw-w-full"
              >Go to login</PawButtonFilled
            >
          </div>
        </div>

        <ion-toast
          position="top"
          :is-open="props.success"
          message="Successfully reset password"
          :icon="ToastSuccess"
          :duration="1000"
          class="success-toast"
        ></ion-toast>
      </div>
    </ion-content>
  </ion-page>
</template>
