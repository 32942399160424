import { useClient } from '@/composables/useClient'
import type { Subscription, SubscriptionContract } from '@repo/api-types'
import { UpdateSubscriptionPlanBodySchemaValues } from '@repo/api-types/src/ecommerce/validations/subscriptionContract'
import { FeedingPlanType } from '@repo/api-types/src/recommendation'
import { ServingSizeOption } from '@lyka-pet-food/lyka-shared-types/lib/types/ProductService'

export const useSubscriptions = () => {
  const getSubscriptions = async (): Promise<{
    error?: string | null
    subscriptions?: Subscription[]
    subContracts?: SubscriptionContract[]
  }> => {
    const client = await useClient()
    const { status, body } = await client.ecommerce.getSubscriptions()

    let error = null
    if (status == 500) {
      error = body.error
      return { error }
    } else if (status == 200) {
      return {
        subscriptions: body.subscriptions,
        subContracts: body.subContracts,
      }
    }

    return { error: 'Unspecified server error' }
  }

  const updateSubscription = async (
    subscriptionId: string,
    values: UpdateSubscriptionPlanBodySchemaValues,
  ): Promise<{ error?: string | null; subscription?: Subscription }> => {
    const client = await useClient()
    const { status, body } = await client.ecommerce.updateSubscription({
      body: {
        ...values,
      },
      params: {
        id: subscriptionId,
      },
    })

    let error = null
    let subscription: Subscription | undefined = undefined
    if (status === 200) {
      subscription = body.subscription
    } else if (status === 404 || status === 500) {
      error = body.error
    }
    return { error, subscription }
  }

  const updateFeedingPlan = async (
    id: string,
    contractId: string,
    petId: string,
    feedingPlanType: FeedingPlanType,
    servingSize: ServingSizeOption,
  ): Promise<{
    error?: string | null
    subscription?: Subscription
    subContract?: SubscriptionContract
  }> => {
    const client = await useClient()
    const { status, body } = await client.ecommerce.updateFeedingPlan({
      body: {
        petId,
        feedingPlanType,
        servingSize,
      },
      params: {
        id,
        contractId,
      },
    })

    let error = null
    let subscription: Subscription | undefined = undefined
    let subContract: SubscriptionContract | undefined = undefined
    if (status === 200) {
      subscription = body.subscription
      subContract = body.subContract
    } else if (status === 404 || status === 500) {
      error = body.error
    }
    return { error, subscription, subContract }
  }

  return {
    getSubscriptions,
    updateSubscription,
    updateFeedingPlan,
  }
}
