import { ServerInferRequest } from '@ts-rest/core'
import { useClient } from '@/composables/useClient'
import { contract } from '@repo/api-contracts'

type ResetPasswordRequest = ServerInferRequest<typeof contract.auth.resetPassword>

export const useResetPassword = async ({ password, previousPassword }: ResetPasswordRequest['body']) => {
  const client = await useClient()
  const { status, body } = await client.auth.resetPassword({
    body: {
      previousPassword,
      password,
    },
  })

  let success = false
  let error = null
  if (status === 200) {
    success = true
  } else if (status === 500) {
    error = body.error
  }

  return { error, success }
}
