<script setup lang="ts">
import { IonApp, IonRouterOutlet, IonContent } from '@ionic/vue'
import { useMobile } from '@/composables/useMobile'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { useAuthStore } from '@/stores/auth'
import UpdateSuccess from '@/components/UpdateSuccess.vue'
import { useAppStateStore } from './stores/appState'
import { computed } from 'vue'

const authStore = useAuthStore()
const isMobile = useMobile().isMobile
const appStateStore = useAppStateStore()

const successState = computed(() => appStateStore.successState)
const loadingState = computed(() => appStateStore.loadingState)
const footerState = computed(() => appStateStore.footerState)
</script>

<template>
  <ion-app>
    <UpdateSuccess v-if="successState || loadingState" :loading="loadingState" />

    <Header v-if="!isMobile || !authStore.isAuthenticated" />

    <ion-content>
      <ion-router-outlet animated="false" />
    </ion-content>

    <Footer v-if="isMobile && footerState" />
  </ion-app>
</template>
