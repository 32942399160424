<script setup lang="ts">
import { ref } from 'vue'
import { PawButtonFilled, PawHeading, PawTextField, PawFormGroup } from '@lyka-pet-food/lyka-common'

const email = ref('')

defineProps({
  loading: Boolean,
})

const emit = defineEmits<{
  (e: 'submit', email: string): void
}>()

const forgotPassword = async () => {
  emit('submit', email.value)
}
</script>

<template>
  <div class="tw-flex tw-flex-col tw-space-y-6 tw-w-full md:tw-w-[400px]">
    <div class="tw-text-center tw-space-y-2">
      <PawHeading size="xl" class="!tw-font-primary tw-font-bold">Reset password</PawHeading>
      <div>
        Enter your email below and we'll send you a link to reset your password if it matches an existing account.
      </div>
    </div>
    <form id="forgot-password-form" @submit.prevent="forgotPassword">
      <PawFormGroup class="tw-space-y-6">
        <PawTextField
          name="forgot-password-email"
          v-model="email"
          :required="true"
          type="email"
          id="email"
          label="Email"
          placeholder="Email address"
        />
        <PawButtonFilled type="submit" form="forgot-password-form" class="tw-w-full" :loading="loading"
          >Reset password</PawButtonFilled
        >
      </PawFormGroup>
    </form>
  </div>
</template>
