<script setup lang="ts">
import { PawDivider, PawButtonOutlined, PawButtonFilled } from '@lyka-pet-food/lyka-common'
import { computed } from 'vue'

const props = defineProps<{
  totalQuantity: number
  totalPrice: number
}>()

const emits = defineEmits<{
  (e: 'cancel'): void
  (e: 'confirm'): void
}>()
</script>

<template>
  <div
    class="tw-w-full tw-bg-surface-light tw-p-4 tw-rounded-t-3xl md:tw-rounded-none tw-space-y-4 tw-pb-4 tw-fixed tw-bottom-0 tw-shadow-[2px_0px_10px_3px_rgba(0,0,0,0.05)] lg:tw-flex lg:tw-justify-center"
  >
    <div class="tw-flex tw-justify-between tw-space-x-4 tw-max-w-[1076px] tw-w-full">
      <PawButtonOutlined class="tw-border-solid tw-w-1/2 md:tw-w-[264px]" @click="emits('cancel')" name="Cancel"
        >Cancel</PawButtonOutlined
      >

      <div class="tw-hidden md:tw-flex tw-justify-between tw-items-center md:tw-space-x-4">
        <div>{{ totalQuantity }} items - New Total: ${{ totalPrice.toFixed(2) }}</div>
      </div>

      <PawButtonFilled class="tw-w-1/2 md:tw-w-[264px]" @click="emits('confirm')" name="Confirm"
        >Confirm</PawButtonFilled
      >
    </div>
  </div>
</template>
