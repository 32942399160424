<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { pluralize } from '@/utils/pluralize'
import { PawAccordion, PawDivider, PawRadio, PawRadioWrap } from '@lyka-pet-food/lyka-common'
import { EditProductItem } from '@/types/product'
import ChevronDownIcon from '@/assets/icons/chevron-down.svg'
import ChevronUpIcon from '@/assets/icons/chevron-up.svg'
import { BillingCycleOrder } from '@repo/api-types'
import { useSubscriptionsStore } from '@/stores/subscriptions'

const props = defineProps<{
  items: EditProductItem[]
  order?: BillingCycleOrder
}>()

const emits = defineEmits<{
  (e: 'updateFrequency', value: number): void
}>()

const subscriptionsStore = useSubscriptionsStore()

const subscriptionState = ref(false)
const showProducts = ref(false)

const subscriptionFrequencyIndex = ref(0)

const subscriptionContract = subscriptionsStore.userSubContracts?.find(
  (x) => x.id === props.order?.subscriptionContractId,
)

const showFrequencies = ref(false)

const subscriptionFrequencies = computed(() => {
  if (!subscriptionContract) return []

  return [subscriptionContract.billingDeliveryRecurringIntervalValue]
})

watch(subscriptionState, () => {
  if (subscriptionState.value) {
    emits('updateFrequency', subscriptionFrequencies.value[subscriptionFrequencyIndex.value])
  } else {
    emits('updateFrequency', 0)
  }
})

const toggleSubscription = () => {
  subscriptionState.value = !subscriptionState.value
}

const toggleFrequencies = () => {
  showFrequencies.value = !showFrequencies.value
}

const toggleProducts = () => {
  showProducts.value = !showProducts.value
}

const onSelectFrequency = (index: number) => {
  subscriptionFrequencyIndex.value = index
  showFrequencies.value = false
  subscriptionState.value = true
}

const totalProductItems = computed(() => {
  return props.items.reduce((a, b) => a + b.quantity, 0)
})

const totalProductPrice = computed(() => {
  return props.items.reduce((a, b) => a + (b.price ?? 0) * b.quantity, 0).toFixed(2)
})
</script>

<template>
  <div class="tw-w-full tw-space-y-4">
    <div class="tw-flex tw-justify-between tw-w-full tw-cursor-pointer" @click="toggleProducts">
      <div class="tw-flex">
        {{ totalProductItems }} item <img :src="showProducts ? ChevronUpIcon : ChevronDownIcon" class="tw-pl-2" />
      </div>
      <div class="tw-font-bold">Total: ${{ totalProductPrice }}</div>
    </div>

    <div v-if="showProducts" class="tw-item-pop-up tw-item-pop-up-shadow" @click="toggleProducts">
      <div class="tw-space-y-3" v-for="(item, idx) in props.items" :key="idx">
        <div class="tw-flex tw-justify-between tw-w-full">
          <div class="tw-flex">
            {{ item.title }} <img src="@/assets/icons/repeat-brand-green.svg" class="tw-pl-2 tw-pb-1" />
          </div>
          <div>Qty: {{ item.quantity }}</div>
        </div>
        <PawDivider />
      </div>

      <div class="tw-flex tw-justify-between">
        <div class="tw-flex">
          {{ totalProductItems }} item <img :src="showProducts ? ChevronUpIcon : ChevronDownIcon" class="tw-pl-2" />
        </div>
        <div class="tw-font-bold">Total: ${{ totalProductPrice }}</div>
      </div>
    </div>

    <PawDivider />

    <div v-if="showProducts" class="md:tw-hidden tw-space-y-4" v-for="(item, idx) in props.items" :key="idx">
      <div class="tw-flex tw-justify-between tw-w-full">
        <div class="tw-flex">
          {{ item.title }} <img src="@/assets/icons/repeat-brand-green.svg" class="tw-pl-2 tw-pb-1" />
        </div>
        <div>Qty: {{ item.quantity }}</div>
      </div>
      <PawDivider />
    </div>

    <div class="tw-flex tw-row tw-space-x-2 tw-relative tw-w-fit">
      <PawRadio v-model="subscriptionState" :input-value="true" @click="toggleSubscription">
        <span class="tw-cursor-pointer">Send this extras every </span>
      </PawRadio>

      <div
        v-if="showFrequencies"
        class="tw-absolute tw-bg-surface-light tw-z-20 tw-bottom-8 -tw-right-12 tw-p-2 tw-rounded-2xl tw-border tw-item-pop-up-shadow"
      >
        <div
          v-for="(frequency, index) in subscriptionFrequencies"
          :key="index"
          class="tw-cursor-pointer hover:tw-bg-surface-contrast tw-p-3 tw-rounded-xl"
          @click="onSelectFrequency(index)"
        >
          {{ index > 0 ? `${index + 1} orders (${frequency * (index + 1)} weeks)` : `order (${frequency} week)` }}
        </div>
      </div>

      <div
        class="tw-flex tw-bg-surface-light tw-border tw-border-secondary tw-px-1.5 tw-rounded-md tw-cursor-pointer tw-relative tw-item-pop-up-shadow -tw-translate-y-0.5"
        @click="toggleFrequencies"
      >
        {{ subscriptionFrequencyIndex > 0 ? `${subscriptionFrequencyIndex + 1} orders` : 'order'
        }}<img src="@/assets/icons/repeat-solid.svg" class="tw-ml-1.5" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.tw-item-pop-up-shadow {
  @apply tw-shadow-[2px_6px_20px_0_rgba(0,0,0,0.08)];
}
.tw-item-pop-up {
  @apply tw-w-[359px] tw-bottom-[54.5px] tw-justify-between tw-cursor-pointer tw-border -tw-translate-x-4 tw-bg-surface-primary tw-p-4 tw-absolute;
  @apply tw-hidden md:tw-flex tw-space-y-3 tw-flex-col tw-rounded-2xl tw-z-20;
}
</style>
