<script setup lang="ts">
const props = defineProps<{
  selected?: string
  value: string
}>()

const emits = defineEmits<{
  (e: 'select', value: string): void
}>()

const onSelect = () => {
  emits('select', props.value)
}
</script>
<template>
  <div
    class="tw-relative tw-flex tw-flex-row tw-rounded-xl tw-bg-white tw-py-4 tw-pl-3 tw-pr-6 tw-items-center tw-border hover:tw-bg-surface-brand-secondary hover:tw-bg-opacity-50 hover:tw-border-content-primary hover:tw-cursor-pointer"
    :class="{
      '!tw-bg-surface-brand-secondary !tw-bg-opacity-50 !tw-border-content-primary hover:tw-cursor-default':
        selected === value,
    }"
    @click="onSelect"
  >
    <div class="tw-absolute tw-top-4 tw-right-4" v-if="selected === value">
      <img src="@/assets/icons/tick-green.svg" class="tw-h-3 tw-w-3" />
    </div>
    <slot />
  </div>
</template>
