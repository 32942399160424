<script setup lang="ts">
import { ref, computed } from 'vue'
import { IonPage } from '@ionic/vue'
import { RetrieveDogReturnDto, UpdateAnimalDto } from '@repo/api-types/src/entities'
import Dog from '@/views/Dog.vue'
import EditDogDetails from '@/views/EditDogDetails.vue'
import EditDogProfile from '@/views/EditDogProfile.vue'
import EditDogProfileReview from '@/views/EditDogProfileReview.vue'
import EditDogMealPlan from '@/views/EditDogMealPlan.vue'
import EditMeals, { EditProductMeal } from '@/views/EditMeals.vue'
import { useAnimalsStore } from '@/stores/animals'
import { useDogs } from '@/composables/useDogs'
import { useSubscriptionsStore } from '@/stores/subscriptions'
import { useProductsStore } from '@/stores/products'
import { useAppStateStore } from '@/stores/appState'
import { useRecommendation } from '@/composables/useRecommendation'
import { SERVING_SIZE_OPTIONS } from '@lyka-pet-food/lyka-shared-types/lib/types/ProductService'
import { FeedingPlan } from '@repo/api-types/src/recommendation'
import { useOrdersStore } from '@/stores/orders'
import { UpdateProductVariant, useOrders } from '@/composables/useOrders'
import { ProductType } from '@/types/products'

const animalsStore = useAnimalsStore()
const subscriptionsStore = useSubscriptionsStore()
const productsStore = useProductsStore()
const appStateStore = useAppStateStore()
const ordersStore = useOrdersStore()

const currentDog = ref<RetrieveDogReturnDto>()
const feedingPlans = ref<FeedingPlan[]>()

const toggleEditMeals = ref(false)
const toggleEditDogMealPlan = ref(false)
const toggleEditPetDetails = ref(false)
const toggleDogProfile = ref(false)
const toggleReviewFeedingPlan = ref(false)
const currentPetIndex = ref(0)

const currentDogSubContract = computed(() =>
  subscriptionsStore.getSubscriptionContractByPetId(currentDog?.value?.identifier as string),
)
const currentDogSubscription = computed(() =>
  subscriptionsStore.getSubscriptionByPetId(currentDog?.value?.identifier as string),
)

const productMeals = computed(() => {
  return productsStore.allProducts?.filter((x) => x?.productType === ProductType.MEAL)
})

const onClickCancelEditPet = () => {
  toggleEditPetDetails.value = false
}

const onEditDogDetails = (dog: RetrieveDogReturnDto, petIndex: number) => {
  currentDog.value = dog
  currentPetIndex.value = petIndex
  toggleEditPetDetails.value = true
}

const onClickCancelEditDogProfile = () => {
  toggleDogProfile.value = false
}

const onEditDogProfile = (dog: RetrieveDogReturnDto) => {
  currentDog.value = dog
  toggleDogProfile.value = true
}

const onSaveDogProfile = async (identifier: string, dog: UpdateAnimalDto) => {
  const { success, error } = await useDogs().updateDog(identifier, dog)
  if (success) {
    await animalsStore.loadDogs()
    toggleDogProfile.value = false
    toggleReviewFeedingPlan.value = true
  }
}

const onSaveDogDetails = async (identifier: string, dog: UpdateAnimalDto, data: Partial<UpdateAnimalDto>) => {
  appStateStore.updateLoadingState(true)

  const { success, error } = await useDogs().updateDog(identifier, { ...dog, ...data })

  if (error) {
    console.error('Failed to update dog')
    return
  }

  await animalsStore.load()

  appStateStore.updateLoadingState(false)
  appStateStore.updateSuccessState(true)
  setTimeout(() => {
    appStateStore.updateSuccessState(false)
    toggleEditPetDetails.value = false
  }, 800)
}

const onReviewDogProfileConfirm = () => {
  toggleReviewFeedingPlan.value = false
}

const onEditDogMealPlan = async (dog: RetrieveDogReturnDto) => {
  currentDog.value = dog

  const currentSubscription = subscriptionsStore.getSubscriptionByPetId(dog.identifier as string)

  const currentServing = currentSubscription?.dailyServingSizeInGrams

  const currentServingFullBowlAdjusted =
    currentSubscription?.mealPlanTemplateId === 'half_bowl' ? String(Number(currentServing) * 2) : currentServing

  const currentServingOption = SERVING_SIZE_OPTIONS.find(
    (servingSize) =>
      currentServingFullBowlAdjusted && parseInt(servingSize) === parseInt(currentServingFullBowlAdjusted),
  )

  if (!currentServingOption) {
    alert('No serving size set for this dog')
    return
  }

  appStateStore.updateLoadingState(true)

  const { recommendation } = await useRecommendation().getRecommendation(dog.identifier, currentServingOption)

  appStateStore.updateLoadingState(false)

  if (!recommendation || !recommendation.feedingPlansRecommendation) {
    alert('No plans found for this dog')
    return
  }

  feedingPlans.value = recommendation.feedingPlansRecommendation
  toggleEditDogMealPlan.value = true
}

const onUpdateMealPlanStatus = async (loading: boolean, successState: boolean) => {
  if (loading) {
    appStateStore.updateLoadingState(true)
  }

  if (successState) {
    await Promise.all([productsStore.load(), subscriptionsStore.load(), animalsStore.load(), ordersStore.load()])
    appStateStore.updateLoadingState(false)
    appStateStore.updateSuccessState(true)
    // FIXME: Why a timeout?
    setTimeout(() => {
      appStateStore.updateSuccessState(false)
      toggleEditDogMealPlan.value = false
    }, 800)
  }
}

const onEditDogProfileMeals = (dog: RetrieveDogReturnDto) => {
  currentDog.value = dog
  toggleEditMeals.value = true
}

const onCancelEditMeals = () => {
  toggleEditMeals.value = false
}

const onConfirmEditMeals = async (updatedProducts: Array<EditProductMeal>) => {
  const subscriptionContractId = currentDogSubContract.value?.id
  // FIXME: Is this right? Should this always be 1 in this case?
  const orderCycleIndex = '1'
  const subscriptionPouchSize = currentDogSubscription.value?.pouchSizeInGrams

  if (!subscriptionContractId || !orderCycleIndex || !subscriptionPouchSize) {
    return
  }

  let updatedVariants = [] as Array<UpdateProductVariant>
  updatedProducts.forEach((x) => {
    const productVariant =
      x?.productId && productsStore.getProductVariantByProductIdPouchSize(x.productId, subscriptionPouchSize)

    if (productVariant && productVariant.productVariantId) {
      updatedVariants.push({
        productVariantId: productVariant.productVariantId,
        quantity: x?.quantity,
      })
    }
  })
  appStateStore.updateLoadingState(true)

  const updateResult = await useOrders().updateMeals(subscriptionContractId, orderCycleIndex, updatedVariants)

  if (!updateResult.error) {
    await Promise.all([ordersStore.load(), subscriptionsStore.load()])
  }

  appStateStore.updateLoadingState(false)
  appStateStore.updateSuccessState(true)
  toggleEditMeals.value = false
  setTimeout(() => {
    appStateStore.updateSuccessState(false)
  }, 500)
}

const onClickCancelEditDogMealPlan = () => {
  toggleEditDogMealPlan.value = false
}
</script>

<template>
  <ion-page>
    <Dog
      v-if="
        !toggleEditPetDetails &&
        !toggleDogProfile &&
        !toggleReviewFeedingPlan &&
        !toggleEditDogMealPlan &&
        !toggleEditMeals
      "
      :pet-index="currentPetIndex"
      @edit-details="onEditDogDetails"
      @edit-profile="onEditDogProfile"
      @edit-meal-plan="onEditDogMealPlan"
      @edit-meals="onEditDogProfileMeals"
    />

    <EditDogDetails
      v-if="toggleEditPetDetails && currentDog"
      :dog="currentDog"
      @cancel-edit-pet="onClickCancelEditPet"
      @confirm-edit-pet="onSaveDogDetails"
    />

    <EditDogMealPlan
      v-if="toggleEditDogMealPlan && currentDog && feedingPlans"
      :dog="currentDog"
      :feeding-plans="feedingPlans"
      @cancel-edit-meal-plan="onClickCancelEditDogMealPlan"
      @update-meal-plan-status="onUpdateMealPlanStatus"
    />

    <EditDogProfile
      v-if="toggleDogProfile && currentDog"
      :dog="currentDog"
      @cancel-edit-profile="onClickCancelEditDogProfile"
      @save-edit-profile="onSaveDogProfile"
    />

    <EditMeals
      v-if="toggleEditMeals"
      :dog-name="currentDog?.name"
      :line-items="currentDogSubContract?.lines"
      :total-pouches="Number.parseInt(currentDogSubscription?.totalPouches as string)"
      :product-meals="productMeals"
      @confirm-edit-meal="onConfirmEditMeals"
      @cancel-edit-meal="onCancelEditMeals"
    />

    <EditDogProfileReview v-if="toggleReviewFeedingPlan" :dog="currentDog" @confirm="onReviewDogProfileConfirm" />
  </ion-page>
</template>
