import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { ProductVariant, type Product } from '@repo/api-types'
import { useProducts } from '@/composables/useProducts'

export const useProductsStore = defineStore('products', () => {
  const allProducts = ref<Product[]>()
  const allProductVariants = ref<ProductVariant[]>()
  const loading = ref(false)

  const load = async () => {
    loading.value = true
    const { products, productVariants, error } = await useProducts()

    // TODO: Move to error  handling
    if (error) {
      console.error(error)
      loading.value = false
      return
    }

    allProducts.value = products
    allProductVariants.value = productVariants
  }

  const getProductById = (id: string): Product | undefined => {
    return allProducts.value?.find((product) => product?.productId === id)
  }

  const getProductVariantById = (id: string): ProductVariant => {
    return allProductVariants.value?.find((product) => product?.productVariantId === id)
  }

  const getImageUrlByProductId = (id: string): string => {
    return getProductById(id)?.imageUrl as string
  }

  const getImageUrlByProductVariantId = (id: string): string => {
    return getProductVariantById(id)?.productVariantFeaturedImage?.url as string
  }

  const getQuantityTypeInGramByVariantId = (id: string): string | undefined => {
    return getProductVariantById(id)?.productVariantOptions?.find((o) => o.optionKey === 'quantity_type_in_gram')
      ?.optionValue
  }

  const getProductVariantByProductId = (id: string): ProductVariant | undefined => {
    return allProductVariants.value?.find((product) => product?.productId === id)
  }

  const getProductVariantByProductIdPouchSize = (id: string, pouchSize: string): ProductVariant => {
    return allProductVariants.value?.find(
      (x) =>
        x?.productId === id &&
        x?.productVariantOptions?.find(
          (y) => y.optionKey === 'quantity_type_in_gram' && y.optionValue === `0${pouchSize}`,
        ),
    )
  }

  const getProductByProductVariantId = (id: string): Product | undefined => {
    return getProductById(getProductVariantById(id)?.productId as string)
  }

  return {
    loading,
    allProducts,
    allProductVariants,
    getImageUrlByProductId,
    getImageUrlByProductVariantId,
    getProductVariantById,
    getProductById,
    getQuantityTypeInGramByVariantId,
    getProductByProductVariantId,
    getProductVariantByProductId,
    getProductVariantByProductIdPouchSize,
    load,
  }
})
