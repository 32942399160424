<script setup lang="ts">
import { computed } from 'vue'
import { PawHeading } from '@lyka-pet-food/lyka-common'
import { OrderStatus } from '@/types/order'

const props = withDefaults(
  defineProps<{
    orderStatus: string
    date: string
    active: boolean
  }>(),
  {
    active: true,
  },
)

const dateMonth = computed(() => new Date(props.date).toLocaleDateString('en-AU', { day: 'numeric', month: 'short' }))
const dayOfWeek = computed(() => new Date(props.date).toLocaleDateString('en-AU', { weekday: 'short' }))
const orderStatusText = computed(() => {
  if (props.orderStatus === OrderStatus.UPNEXT) {
    return 'UP NEXT'
  }
  return props.orderStatus
})
</script>

<template>
  <div
    class="tw-text-center tw-rounded-450 tw-border-2 tw-uppercase tw-text-nowrap tw-width-height-transition"
    :class="[
      active
        ? ['tw-bg-surface-brand-accent', 'tw-border-surface-brand-accent', 'lg:tw-h-[152px]', 'tw-h-[122px]']
        : ['tw-bg-surface-brand-secondary', 'tw-border-surface-brand-secondary', 'lg:tw-h-[131px]', 'tw-h-[101px]'],
    ]"
  >
    <div class="tw-text-body-xs tw-m-1" :class="active ? 'tw-text-content-invert' : 'tw-text-content-primary'">
      {{ orderStatusText }}
    </div>

    <div class="tw-bg-surface-primary tw-text-body-md tw-rounded-450">
      <div
        class="tw-flex tw-justify-center tw-flex-col tw-width-height-transition"
        :class="[
          active
            ? ['lg:tw-w-[142px]', 'tw-w-[112px]', 'lg:tw-h-[124px]', 'tw-h-[94px]']
            : ['lg:tw-w-[121px]', 'tw-w-[91px]', 'lg:tw-h-[103px]', 'tw-h-[73px]'],
        ]"
      >
        <PawHeading :size="active ? '3xl' : '2xl'">{{ dateMonth }}</PawHeading>
        <PawHeading size="xl" type="subheading">{{ dayOfWeek }}</PawHeading>
      </div>
    </div>
  </div>
</template>
<style scoped>
.tw-width-height-transition {
  @apply tw-transition-all tw-duration-300;
}
</style>
