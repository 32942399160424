import { initContract } from '@ts-rest/core'
import { ErrorResponseSchema } from './general'
import z from 'zod'

const c = initContract()

export const fulfillmentContract = c.router({
  getMealPlanTemplate: {
    method: 'GET',
    path: '/api/v1/meal-plans',
    responses: {
      200: z.object({ mealPlan: z.record(z.number()) }),
      401: ErrorResponseSchema,
    },
  },
})
