<script setup lang="ts">
import ViewLayout from '@/components/ViewLayout.vue'
import { PawButtonFilled, PawHeading } from '@lyka-pet-food/lyka-common'
import { computed, withDefaults } from 'vue'
import moment from 'moment-timezone'

const props = withDefaults(
  defineProps<{
    dogName?: string
    loading?: boolean
    nextDeliveryDate?: Date
  }>(),
  {
    loading: false,
  },
)

const nextDeliveryDateInString = computed<string>(() =>
  props.nextDeliveryDate ? moment(props.nextDeliveryDate).format('ddd, DD MMM') : '',
)

const emits = defineEmits<{
  (e: 'dismiss'): void
}>()
</script>

<template>
  <ViewLayout>
    <div
      class="tw-flex tw-h-full tw-max-w-[1140px] tw-flex-col tw-flex-nowrap tw-items-center tw-justify-center tw-space-y-8 tw-pb-20 tw-text-center"
    >
      <img
        src="@/assets/icons/success-tick.png"
        class="tw-block tw-h-[64px] tw-w-[64px] tw-cursor-pointer"
        alt="info"
      />
      <PawHeading data-testid="submission-heading" size="3xl" class="tw-px-10"
        >Successfully updated {{ props.dogName }}’s feeding plan</PawHeading
      >
      <div class="tw-text-sm">
        This change will be reflected in your next delivery on
        <span class="tw-font-bold">{{ nextDeliveryDateInString }}</span
        >.
      </div>
      <PawButtonFilled class="tw-w-1/2 md:tw-w-[264px]" @click="emits('dismiss')" :disabled="loading" name="Confirm"
        >Okay</PawButtonFilled
      >
    </div>
  </ViewLayout>
</template>
