<script setup lang="ts">
import type { VNode } from 'vue'
import { computed, ref, useSlots } from 'vue'

type AnyVNode = VNode<any, any>

const model = defineModel()
const slots = useSlots()
const selectedValue = ref(model.value)

const children = computed((): AnyVNode[] => {
  if (slots.default) {
    return slots.default()
  }
  return [] as AnyVNode[]
})

const onSelect = (value: string) => {
  selectedValue.value = value
  model.value = value
}
</script>

<template>
  <div v-for="(child, index) in children" :key="index">
    <component :is="child" :key="child.key" :selected="selectedValue" @select="onSelect"></component>
  </div>
</template>
