import { ServerInferRequest } from '@ts-rest/core'
import { useAuthStore } from '@/stores/auth'
import { useClient } from '@/composables/useClient'
import { contract } from '@repo/api-contracts'

type LoginRequest = ServerInferRequest<typeof contract.auth.login>

export const useLogin = async ({ email, password }: LoginRequest['body']) => {
  const authStore = useAuthStore()
  const client = await useClient()
  const { status, body } = await client.auth.login({
    body: {
      email,
      password,
    },
  })

  let error = null
  let token = null
  if (status === 200) {
    await authStore.setAccessToken(body.accessToken)
    await authStore.setCustomerToken(body.customerToken)
    token = body.accessToken
  } else if (status === 401) {
    error = body.error
  }
  return { error, token }
}
