import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useAppStateStore = defineStore('appState', () => {
  const preloaded = ref(false)
  const loadingState = ref(false)
  const successState = ref(false)
  const footerState = ref(true)

  const updateSuccessState = (updateState: boolean) => (successState.value = updateState)
  const updateLoadingState = (updateState: boolean) => (loadingState.value = updateState)
  const updateFooterState = (updateState: boolean) => (footerState.value = updateState)
  const updatePreloadState = (updateState: boolean) => (preloaded.value = updateState)

  return {
    loadingState,
    successState,
    footerState,
    preloaded,
    updateSuccessState,
    updateLoadingState,
    updateFooterState,
    updatePreloadState,
  }
})
