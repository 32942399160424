export interface BirthdayYearMonth {
  year: number
  month: number
}

export const birthdayToAge = (birthday: string | undefined): string => {
  if (!birthday) return ''
  const today = new Date()
  const birthDate = new Date(birthday)
  let age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  if (age > 0) return `${age} year${age === 1 ? '' : 's'}`

  return `${m} month${m === 1 ? '' : 's'}`
}

export const birthdayToYearMonth = (birthday: string | undefined): BirthdayYearMonth => {
  if (!birthday) return { year: 0, month: 0 }
  const today = new Date()
  const birthDate = new Date(birthday)
  let age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return { year: age, month: m }
}
