import { initContract } from '@ts-rest/core'
import { CourierDeliveryScheduleSchema } from '@repo/api-types'
import z from 'zod'
import { ErrorResponseSchema } from './general'

const c = initContract()

export const courierContract = c.router({
  getDeliverySchedulesByPostCode: {
    method: 'GET',
    path: '/couriers/delivery-schedules', // TODO: confirm with ops the endpoint for getting the delivery-schedule
    query: z.object({ postCode: z.string(), suburb: z.string() }),
    responses: {
      200: z.array(CourierDeliveryScheduleSchema), // TODO: talk to the team regarding standard response schema. should we add result?
      401: ErrorResponseSchema,
    },
  },
})
