<script setup lang="ts">
import { PawQuantitySelector } from '@lyka-pet-food/lyka-common'
import { defineProps, ref, defineEmits } from 'vue'

const MIN_QUANTITY = 0
const MAX_QUANTITY = 99

const props = withDefaults(
  defineProps<{
    subTitle?: string
    title?: string
    quantity: number
    imagePath?: string
    mealIconPath?: string
    recommended?: boolean
    unrecommendedReason?: string
  }>(),
  {
    recommended: true,
  },
)

const mealsQuantity = ref(props.quantity)

const getNewQuantity = (newQuantity: number) => {
  if (Number.isNaN(newQuantity)) {
    return MIN_QUANTITY
  }
  if (newQuantity >= MIN_QUANTITY && newQuantity <= MAX_QUANTITY) {
    return Math.round(newQuantity)
  }
  if (newQuantity < MIN_QUANTITY) {
    return MIN_QUANTITY
  }
  if (newQuantity > MAX_QUANTITY) {
    return MAX_QUANTITY
  }
  return Math.round(mealsQuantity.value)
}

const emits = defineEmits<{
  (e: 'updateQuantity', quantity: number): void
  (e: 'viewMealsDetail'): void
}>()

const onClickAddMeals = (): void => {
  mealsQuantity.value = getNewQuantity(mealsQuantity.value + 1)
  emits('updateQuantity', mealsQuantity.value)
}

const onClickRemoveMeals = (): void => {
  mealsQuantity.value = getNewQuantity(mealsQuantity.value - 1)
  emits('updateQuantity', mealsQuantity.value)
}

const onClickViewMealsDetail = (): void => {
  emits('viewMealsDetail')
}

const onUpdateQuantity = (quantity: number | undefined): void => {
  mealsQuantity.value = getNewQuantity(Number(quantity) || MIN_QUANTITY)
  emits('updateQuantity', mealsQuantity.value)
}
</script>

<template>
  <div class="tw-flex tw-w-full tw-space-x-5 md:tw-flex-col md:tw-space-x-0 md:tw-space-y-4">
    <div
      class="tw-flex tw-h-[115px] tw-w-1/3 tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-xl tw-bg-surface-brand-secondary max-[360px]:tw-h-[85px] md:tw-h-[164px] md:tw-w-[229px]"
      @click="onClickViewMealsDetail"
    >
      <div
        v-if="!props.recommended"
        class="tw-absolute tw-z-10 tw-h-[115px] tw-w-1/3 tw-bg-white/5 tw-backdrop-grayscale max-[360px]:tw-h-[85px] md:tw-h-[164px] md:tw-w-[229px]"
      ></div>

      <div class="tw-relative tw-h-auto tw-w-4/5">
        <img :src="props.imagePath" class="tw-h-auto tw-w-full tw-scale-[1.3] md:tw-scale-100" />
        <img
          :src="props.mealIconPath"
          class="tw-absolute -tw-bottom-1.5 tw-right-2 tw-w-[31px] max-[360px]:tw-w-1/3 md:tw-bottom-9 md:tw-right-5"
        />
      </div>
    </div>

    <div class="tw-flex tw-w-2/3 tw-flex-col tw-space-y-4 md:tw-w-full">
      <div class="tw-space-y-0.5">
        <div class="tw-text-body-md tw-flex tw-pb-1">
          {{ props.subTitle }}
        </div>
        <div class="tw-text-body-lg">{{ props.title }}</div>
        <div class="tw-cursor-pointer tw-text-content-accent tw-underline" @click="onClickViewMealsDetail">
          Show details
        </div>
      </div>

      <div class="tw-w-[165px] tw-pt-2 md:tw-pt-0">
        <PawQuantitySelector
          data-testid="quantity-selector"
          v-model="mealsQuantity"
          @click-plus="onClickAddMeals"
          @click-minus="onClickRemoveMeals"
          @update:model-value="onUpdateQuantity"
          :disable-minus="mealsQuantity <= MIN_QUANTITY"
          :disable-plus="mealsQuantity >= MAX_QUANTITY"
        />
      </div>
    </div>
  </div>
</template>
