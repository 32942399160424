<script setup lang="ts">
import { IonContent, IonPage } from '@ionic/vue'
import ForgotPasswordForm from '@/components/ForgotPasswordForm.vue'

const props = defineProps({
  loading: Boolean,
})

const emit = defineEmits<{
  (e: 'submit', email: string): void
}>()

const submit = (email: string) => {
  emit('submit', email)
}
</script>

<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="tw-h-full tw-flex tw-justify-center tw-items-center tw-px-4">
        <ForgotPasswordForm @submit="submit" :loading="loading" />
      </div>
    </ion-content>
  </ion-page>
</template>
