import { computed } from 'vue'
import { useBreakpoints } from '@/composables/useBreakpoints'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useMobile = () => {
  const breakpoints = useBreakpoints()

  const isIOS = computed((): boolean => {
    const _window = window as any

    const userAgent = navigator.userAgent || navigator.vendor || _window.opera

    if (/iPad|iPhone|iPod/.test(userAgent) && !_window.MSStream) {
      return true
    }

    return false
  })

  const isMobile = computed(() => !breakpoints.value.md)
  const isTablet = computed(() => !breakpoints.value.lg)

  return {
    isIOS,
    isMobile,
    isTablet,
  }
}
