<script setup lang="ts">
import { PawButtonFilled } from '@lyka-pet-food/lyka-common'
import { useAuthStore } from '@/stores/auth'
import { useRouter } from 'vue-router'

const authStore = useAuthStore()
const router = useRouter()
const logout = async () => {
  await authStore.removeAccessToken()
  await authStore.removeCustomerToken()
  await router.push({ name: 'login' })
}
</script>

<template>
  <PawButtonFilled @click="logout">Log out</PawButtonFilled>
</template>
