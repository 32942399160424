<script setup lang="ts">
import { RetrieveDogReturnDto } from '@repo/api-types/src/entities'
import { PawHeading, PawButtonFilled } from '@lyka-pet-food/lyka-common'
import { useSubscriptionsStore } from '@/stores/subscriptions'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const props = defineProps<{
  dog?: RetrieveDogReturnDto
}>()

const { getSubscriptionByPetId, getSubContractByPetId } = useSubscriptionsStore()

const subscription = computed(() => getSubscriptionByPetId(props.dog?.identifier as string))
const subscriptionContract = computed(() => getSubContractByPetId(props.dog?.identifier as string))
const servingSize = computed(() => subscription.value?.dailyServingSizeInGrams)
const recommendedServingSize = computed(() => subscription.value?.recommendedDailyServingSizeInGrams)
const servingSizePercentage = computed(
  () =>
    servingSize.value &&
    recommendedServingSize.value &&
    Math.round((parseFloat(servingSize.value) / parseFloat(recommendedServingSize.value)) * 100),
)

const changeServingSize = async () => {
  if (subscription.value && subscriptionContract.value) {
    await router.push({
      name: 'edit-serving-size',
      params: {
        subscriptionId: subscription.value.subscriptionId,
        contractId: subscriptionContract.value.id,
      },
    })
  }
}
</script>

<template>
  <div class="tw-w-full">
    <PawHeading size="2xl" type="subheading"
      ><span class="tw-font-bold">{{ props.dog?.name }}'s</span> meal plan</PawHeading
    >
  </div>

  <div
    class="tw-border-color-brand-secondary tw-rounded-xl tw-border tw-bg-content-invert tw-p-5 tw-pt-1 md:tw-pl-0 md:tw-pb-2 tw-shadow-md md:tw-flex md:tw-justify-between md:tw-items-center"
  >
    <div class="tw-flex tw-justify-between tw-pb-2 md:tw-pb-0 md:tw-flex-row-reverse">
      <div class="tw-flex tw-flex-col tw-justify-center tw-space-y-4">
        <div class="tw-font-bold tw-text-xl">{{ servingSize }}g per day</div>
        <div>Feeding {{ servingSizePercentage }}% Lyka</div>
      </div>
      <div class="tw-w-[150px]">
        <img alt="bowl" src="@/assets/images/dogs/meal-plan-bowl.png" />
      </div>
    </div>

    <div>
      <PawButtonFilled :disabled="!subscription || !subscriptionContract" @click="changeServingSize" block>
        Change serving size
      </PawButtonFilled>
    </div>
  </div>
</template>
