<script setup lang="ts">
import { IonContent, IonPage } from '@ionic/vue'
import {
  PawLoginForm,
  PawFormGroup,
  PawTextField,
  PawTextFieldPassword,
  PawButtonFilled,
  ImgMirrorDog,
  IconEnvelope,
  IconLockClosed,
  PawHeading,
} from '@lyka-pet-food/lyka-common'
import { computed, ref } from 'vue'
import Error from '@/components/Error.vue'

const email = ref('')
const password = ref('')

defineProps({
  loading: Boolean,
  errorMessage: String,
})

const emit = defineEmits<{
  (e: 'submit', email: string, password: string): void
  (e: 'forgotPassword'): void
}>()

const submit = () => {
  // TODO: make use of form validation libarry like vee-validate
  if (email.value && password.value) {
    emit('submit', email.value, password.value)
  }
}

const forgotPassword = () => {
  emit('forgotPassword')
}

// TODO: make use of form validation libarry like vee-validate
</script>

<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="tw-h-full tw-flex tw-justify-center tw-items-center tw-px-4">
        <div class="tw-flex tw-flex-col tw-space-y-4 tw-w-[400px] -tw-mt-28">
          <div class="tw-text-center">
            <PawHeading size="2xl" class="!tw-font-primary tw-font-bold">Log in</PawHeading>
          </div>
          <form id="login-form" @submit.prevent="submit">
            <PawFormGroup class="tw-space-y-4">
              <PawTextField
                v-model="email"
                :disabled="loading"
                type="email"
                name="email"
                id="email"
                label="Email"
                placeholder="Email address"
                role="text"
              />
              <PawTextFieldPassword
                v-model="password"
                :disabled="loading"
                name="password"
                id="password"
                label="Password"
                placeholder="Password"
              />
              <div class="tw-flex tw-flex-row-reverse">
                <a role="link" @click="forgotPassword" class="tw-text-content-accent tw-cursor-pointer"
                  >Forgot password?</a
                >
              </div>

              <Error v-if="errorMessage">{{ errorMessage }}</Error>

              <PawButtonFilled
                type="submit"
                form="login-form"
                class="tw-w-full"
                role="button"
                name="Sign in"
                :loading="loading"
                >Sign in</PawButtonFilled
              >
            </PawFormGroup>
          </form>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>
