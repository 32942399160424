import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import type { Subscription, SubscriptionContract } from '@repo/api-types'
import { useSubscriptions } from '@/composables/useSubscriptions'

export const useSubscriptionsStore = defineStore('subscriptions', () => {
  const userSubscriptions = ref<Subscription[]>()
  const userSubContracts = ref<SubscriptionContract[]>()
  const loading = ref(false)

  const load = async () => {
    loading.value = true
    const { subscriptions, subContracts, error } = await useSubscriptions().getSubscriptions()

    // TODO: Move to error  handling
    if (error) {
      console.error(error)
      loading.value = false
      return
    }

    userSubscriptions.value = subscriptions
    userSubContracts.value = subContracts
  }

  const getSubContractByPetId = (petId: string) => {
    return userSubContracts.value?.find((sub) => sub.petId === petId)
  }
  const getSubscriptionByPetId = (petId: string) => {
    return userSubscriptions.value?.find((sub) => sub.subscriptionForPetId === petId)
  }

  const getSubscriptionContractByPetId = (petId: string) => {
    const subscription = userSubscriptions.value?.find((sub) => sub.subscriptionForPetId === petId)
    return userSubContracts.value?.find((sub) => sub.subscriptionId === subscription?.subscriptionId)
  }

  return {
    loading,
    userSubContracts,
    userSubscriptions,
    getSubscriptionByPetId,
    getSubContractByPetId,
    getSubscriptionContractByPetId,
    load,
  }
})
