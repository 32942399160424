<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useLogin } from '@/composables/useLogin'
import { useSegment } from '@/composables/useSegment'
import { loadAllStores } from '@/router'
import Login from '@/views/Login.vue'

const router = useRouter()

const loading = ref(false)
const errorMessage = ref('')

const doLogin = async (email: string, password: string) => {
  try {
    errorMessage.value = ''
    loading.value = true
    const { error, token } = await useLogin({ email, password })

    if (error) {
      useSegment().track('Login Failed', {
        reason: error,
      })

      errorMessage.value = 'Incorrect email or password. Check your details and try again.'
    } else {
      if (token) {
        useSegment().track('Login Success')
        await loadAllStores()
        router.push({ name: 'deliveries' })
      }
    }
  } finally {
    loading.value = false
  }
}

const forgotPassword = () => {
  router.push({ name: 'forgot-password' })
}
</script>

<template>
  <Login @submit="doLogin" @forgot-password="forgotPassword" :errorMessage="errorMessage" :loading="loading" />
</template>
