<script setup lang="ts">
import { useRouter } from 'vue-router'
import { useConfirmForgotPassword } from '@/composables/useConfirmForgotPassword'
import { ref, onMounted } from 'vue'
import ConfirmForgotPassword from '@/views/ConfirmForgotPassword.vue'

const props = defineProps<{
  confirmationCode: string
  email: string
}>()

const route = useRouter()

onMounted(() => {
  if (!props.confirmationCode || !props.email) {
    // show login if code and email is not passed as query param
    route.push({
      name: 'login',
    })
  }
})

const loading = ref(false)
const successful = ref(false)

const doConfirmForgotPassword = async (password: string) => {
  try {
    loading.value = true
    const { error, success } = await useConfirmForgotPassword({
      email: props.email,
      confirmationCode: props.confirmationCode,
      password,
    })

    if (error) {
      throw error
    }
    successful.value = success
  } finally {
    loading.value = false
  }
}
</script>

<template>
  <ConfirmForgotPassword @submit="doConfirmForgotPassword" :loading="loading" :success="successful" />
</template>
