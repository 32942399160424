import type { ShippingAddress } from '@repo/api-types'

export const transformShippingAddress = ({
  address1,
  address2,
  provinceCode,
  city,
  postCode,
}: ShippingAddress): string => {
  // TODO: Add state
  return [address1, address2, provinceCode, city, postCode].filter((v) => !!v).join(', ')
}
