import { useClient } from '@/composables/useClient'
import type { Product, ProductVariant } from '@repo/api-types'

export const useProducts = async (): Promise<{
  error?: string | null
  products?: Product[]
  productVariants?: ProductVariant[]
}> => {
  const client = await useClient()
  const { status, body } = await client.ecommerce.getProducts()

  let error = null
  if (status == 500) {
    error = body.error
    return { error }
  } else if (status == 200) {
    return { products: body.products, productVariants: body.productVariants }
  }

  return { error: 'Unspecified server error' }
}
