<script setup lang="ts">
import { defineProps, defineEmits } from 'vue'
import type { ProductToEdit } from '@/views/AddProducts.vue'
import { productNameToTitle } from '@/utils/productNameToTitle'

const props = defineProps<{
  product: ProductToEdit
}>()

const emits = defineEmits<{
  (e: 'addQuantity'): void
  (e: 'removeQuantity'): void
  (e: 'viewProductDetail'): void
}>()

const onClickAddProducts = (): void => {
  emits('addQuantity')
}

const onClickRemoveProducts = (): void => {
  emits('removeQuantity')
}

const onClickViewProductDetail = (): void => {
  emits('viewProductDetail')
}
</script>

<template>
  <div class="tw-w-full tw-flex tw-flex-col tw-space-y-3 md:tw-space-y-4 md:tw-space-x-5 md:tw-space-x-0">
    <div
      class="max-[360px]:tw-h-[85px] tw-w-full tw-h-[115px] md:tw-h-[164px] tw-bg-surface-brand-secondary tw-rounded-xl tw-flex tw-items-center tw-justify-center"
      @click="onClickViewProductDetail"
    >
      <div class="tw-w-4/5 tw-h-full tw-justify-center tw-flex tw-relative">
        <img :src="props.product.imageUrl" class="tw-h-full tw-w-auto" />
        <div
          class="tw-bg-surface-light tw-rounded-full tw-w-12 tw-h-12 tw-absolute tw-shadow-[0_2px_12px_0_rgba(0,0,0,0.15)] tw-bottom-4 tw-right-0"
          :class="product.quantity > 0 && 'hover:tw-w-32'"
        >
          <div
            class="tw-flex tw-justify-center tw-items-center tw-h-full"
            :class="product.quantity > 0 && 'tw-quantity-selector'"
          >
            <img
              src="@/assets/icons/trash-can.svg"
              class="tw-selector-delete tw-hidden tw-cursor-pointer"
              @click="onClickRemoveProducts"
            />
            <div class="tw-selector-quantity" :class="product.quantity < 1 && 'tw-hidden'">{{ product.quantity }}</div>
            <img
              src="@/assets/icons/plus-icon.svg"
              class="tw-selector-add tw-cursor-pointer"
              :class="product.quantity > 0 && 'tw-hidden'"
              @click="onClickAddProducts"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="tw-flex tw-flex-col tw-w-full tw-space-y-4">
      <div class="tw-space-y-0.5">
        <div class="tw-flex tw-justify-between">
          <div class="tw-text-body-lg">{{ productNameToTitle(props.product.displayName) }}</div>
          <div class="tw-text-body-lg">${{ props.product.price?.toFixed(2) }}</div>
        </div>
        <div class="tw-text-content-accent tw-underline tw-cursor-pointer" @click="onClickViewProductDetail">
          Show details
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.tw-quantity-selector:hover {
  @apply tw-justify-between tw-px-3;
}
.tw-quantity-selector:hover .tw-selector-delete {
  @apply tw-block;
}

.tw-quantity-selector:hover .tw-selector-add {
  @apply tw-block;
}

.tw-quantity-selector:hover .tw-selector-quantity {
  @apply tw-block;
}
</style>
