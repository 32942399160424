<script setup lang="ts">
import { useAnimalsStore } from '@/stores/animals'
import { PawButtonOutlined, PawHeading } from '@lyka-pet-food/lyka-common'
import { RetrieveDogReturnDto } from '@repo/api-types/src/entities'
import { computed } from 'vue'

const props = defineProps<{
  dog: RetrieveDogReturnDto
}>()

const emit = defineEmits<{
  (e: 'editProfile', pet: RetrieveDogReturnDto): void
}>()

const animalsStore = useAnimalsStore()

const foodAllergiesNames = computed(() => {
  return props.dog?.foodAllergies?.map((id) => animalsStore.getAllergenBySlug(id)?.name)
})

const healthIssuesNames = computed(() => {
  return props.dog?.healthIssues?.map((id) => animalsStore.getHealthIssueBySlug(id)?.name)
})

const previousFoodsNames = computed(() => {
  return props.dog?.previousFoods?.map((id) => animalsStore.getFoodTypeBySlug(id)?.name)
})
</script>

<template>
  <PawHeading size="2xl" type="subheading">Profile</PawHeading>

  <div>
    Keep {{ dog?.name }}'s profile up to date to ensure they get the <strong>right amount of Lyka</strong> for their
    needs.
  </div>

  <div class="tw-bg-white tw-rounded-xl tw-flex tw-flex-col tw-shadow-md tw-w-full">
    <div class="tw-border-b tw-p-4 tw-py-6 tw-flex tw-justify-center md:tw-justify-start">
      <PawButtonOutlined class="tw-border-solid tw-w-full md:tw-w-auto" @click="emit('editProfile', dog)">
        Update & get recommendation
      </PawButtonOutlined>
    </div>
    <div class="tw-p-4 tw-flex tw-flex-col md:tw-flex-row tw-space-y-2 md:tw-space-y-0">
      <div
        class="tw-flex tw-space-x-6 tw-basis-1/3 tw-border-b md:tw-border-b-0 md:tw-border-r tw-pt-2 md:tw-pt-0 tw-pb-4 md:tw-pb-0 tw-pl-2 md:tw-pl-6"
      >
        <div class="tw-flex tw-items-center md:tw-items-start">
          <img src="@/assets/icons/scale-unbalanced-flip.svg" class="tw-w-[30px] tw-h-[30px] tw-mb-2" />
        </div>
        <div>
          <div class="tw-mb-1 tw-text-sm">Weight & Activity</div>
          <div>
            {{ dog?.weight }}kg, <span class="tw-capitalize">{{ dog?.bodyType }}</span
            >, <span class="tw-capitalize">{{ dog?.activityLevel?.replace('_', ' ') }}</span>
          </div>
        </div>
      </div>
      <div
        class="tw-flex tw-space-x-6 tw-basis-1/3 tw-border-b md:tw-border-b-0 md:tw-border-r tw-pb-4 md:tw-pb-2 tw-pl-2"
      >
        <div class="tw-flex tw-items-center md:tw-items-start">
          <img src="@/assets/icons/heart-pulse.svg" class="tw-w-[30px] tw-h-[30px] tw-mb-2" />
        </div>
        <div>
          <div class="tw-mb-2 tw-text-sm">Health</div>
          <div v-if="foodAllergiesNames && foodAllergiesNames.length > 0">
            Intolerances to {{ foodAllergiesNames.join(', ') }}
          </div>
          <div v-else>No Intolerances,</div>
          <div v-if="healthIssuesNames && healthIssuesNames.length > 0">{{ healthIssuesNames.join(', ') }}</div>
          <div v-else>No Health Issues</div>
        </div>
      </div>
      <div class="tw-flex tw-space-x-6 tw-basis-1/3 tw-pt-2 md:tw-pt-0 tw-pl-2 md:tw-pl-6">
        <div class="tw-flex tw-items-center md:tw-items-start">
          <img src="@/assets/icons/bowl-food.svg" class="tw-w-[30px] tw-h-[30px] tw-mb-2" />
        </div>
        <div>
          <div class="tw-mb-1 tw-text-sm">Diet</div>
          <div class="tw-capitalize">{{ dog?.eatingHabits?.replace('_', ' ') }},</div>
          <div v-if="previousFoodsNames && previousFoodsNames.length > 0">
            Previously feed on {{ previousFoodsNames.join(', ') }}
          </div>
          <div v-else>No Previous Foods</div>
        </div>
      </div>
    </div>
  </div>
</template>
