import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import type { Order, BillingCycleOrder } from '@repo/api-types'
import { useOrders } from '@/composables/useOrders'

export const useOrdersStore = defineStore('orders', () => {
  const pastOrders = ref<Order[]>([])
  const currentOrder = ref<Order>({} as Order)
  const futureOrders = ref<BillingCycleOrder[]>([])
  const loading = ref(false)

  const load = async () => {
    loading.value = true
    const { past, current, future, error } = await useOrders().getUserOrders()

    // TODO: Move to error  handling
    if (error) {
      console.error(error)
      loading.value = false
      return
    }

    if (!current || !future) {
      throw new Error('User has no current or future orders')
    }
    currentOrder.value = current
    futureOrders.value = future

    if (past) {
      pastOrders.value = past
    }
    loading.value = false
  }

  const recentOrders = computed<Array<Order>>((): Array<Order> => {
    return [...pastOrders.value, currentOrder.value]
  })

  return {
    loading,
    recentOrders,
    pastOrders,
    currentOrder,
    futureOrders,
    load,
  }
})
