export const dateToLocalString = (date: string | undefined) => {
  if (!date) return ''
  return new Date(date).toLocaleDateString('en-AU', { day: 'numeric', month: 'short' })
}

export const dateToLocalDateFull = (date: string) => {
  return `${new Date(date).toLocaleDateString('en-AU', { day: 'numeric', month: 'short' })} ${new Date(date).toLocaleDateString('en-AU', { weekday: 'long' })}`
}

export const dateToLocalDateShort = (date: string) => {
  return `${new Date(date).toLocaleDateString('en-AU', { day: 'numeric', month: 'short' })} ${new Date(date).toLocaleDateString('en-AU', { weekday: 'short' })}`
}
