<script setup lang="ts">
import { IonPage } from '@ionic/vue'
import { useRouter, useRoute } from 'vue-router'
import { computed, onBeforeMount, ref } from 'vue'
import { SERVING_SIZE_OPTIONS, ServingSizeOption } from '@lyka-pet-food/lyka-shared-types/lib/types/ProductService'
import { useRecommendation } from '@/composables/useRecommendation'
import { FeedingPlan, FeedingPlanType } from '@repo/api-types/src/recommendation'
import { useAnimalsStore } from '@/stores/animals'
import { useSubscriptionsStore } from '@/stores/subscriptions'
import { useSubscriptions } from '@/composables/useSubscriptions'
import { loadingWrapper } from '@/utils/function'
import { useOrdersStore } from '@/stores/orders'
import EditServingSize from '@/views/EditServingSize.vue'
import EditServingSizePlanConfirm from '@/views/EditServingSizePlanConfirm.vue'
import EditServingSizePlanSubmission from '@/views/EditServingSizePlanSubmission.vue'

const router = useRouter()
const route = useRoute()
const { updateFeedingPlan } = useSubscriptions()
const subscriptionId = route.params.subscriptionId
const contractId = route.params.contractId
const animalsStore = useAnimalsStore()

const pets = computed(() => animalsStore.dogs)

const subscriptionsStore = useSubscriptionsStore()

const ordersStore = useOrdersStore()

const nextDeliveryDate = computed(() => {
  const date = ordersStore.futureOrders[0]?.expectedDeliveryDate
  if (!date) {
    return
  }
  return new Date(date)
})

const currentSubscription = computed(() =>
  subscriptionsStore?.userSubscriptions?.find((s) => s.subscriptionId === subscriptionId),
)

const currentDog = computed(() =>
  pets.value.find(
    (pet) =>
      currentSubscription.value?.subscriptionForPetId &&
      currentSubscription.value.subscriptionForPetId === pet.identifier,
  ),
)

const currentSubscriptionContract = computed(() =>
  subscriptionsStore?.userSubContracts?.find((s) => s.id === contractId),
)

const currentServingSize = computed(() => {
  let multiplier = 1
  const dailyServingSizeInGrams = currentSubscription.value?.dailyServingSizeInGrams
  const mealPlanTemplateId = currentSubscription.value?.mealPlanTemplateId
  if (!dailyServingSizeInGrams || !mealPlanTemplateId) {
    return
  }
  if (mealPlanTemplateId === 'half_bowl') {
    multiplier = 2
  }
  return SERVING_SIZE_OPTIONS.find(
    (servingSize) => parseFloat(dailyServingSizeInGrams) * multiplier === parseFloat(servingSize),
  )
})

const currentRecommendationPlan = ref<FeedingPlan | undefined>(undefined)
const selectedRecommendationPlan = ref<FeedingPlan | undefined>(undefined)

const loading = ref<boolean>(false)
const setLoading = (enable: boolean) => (loading.value = enable)

const error = ref<Error | undefined>(undefined)
const setError = (err: Error) => (error.value = err)
const resetError = () => (error.value = undefined)

const selectedServingSize = ref<ServingSizeOption | undefined>(undefined)
const setSelectedServingSize = (servingSize: ServingSizeOption) => (selectedServingSize.value = servingSize)

const recommendedServingSize = computed<ServingSizeOption | undefined>(() =>
  SERVING_SIZE_OPTIONS.find(
    (servingSize) =>
      currentSubscription.value?.recommendedDailyServingSizeInGrams &&
      parseFloat(currentSubscription.value?.recommendedDailyServingSizeInGrams) === parseFloat(servingSize),
  ),
)

const getRecommendationPlan = async (servingSize: ServingSizeOption) => {
  const animalId = currentSubscription.value?.subscriptionForPetId
  if (!animalId) {
    setError(new Error('Could not get animal id'))
    return
  }
  const recommendationResult = await useRecommendation().getRecommendation(animalId, servingSize)
  const planTemplateId = currentSubscription.value?.mealPlanTemplateId
  const plan = recommendationResult.recommendation?.feedingPlansRecommendation?.find((r: FeedingPlan) =>
    planTemplateId?.startsWith(r.feedingPlanType),
  )
  if (!plan) {
    setError(new Error('Could not get recommendation plan'))
    return
  }
  return plan
}

const setCurrentRecommendationPlan = () =>
  loadingWrapper(async () => {
    if (!currentServingSize.value) {
      return
    }
    currentRecommendationPlan.value = await getRecommendationPlan(currentServingSize.value)
  }, setLoading)

const setSelectedRecommendationAndMoveForward = (servingSize: ServingSizeOption) =>
  loadingWrapper(async () => {
    setSelectedServingSize(servingSize)
    selectedRecommendationPlan.value = await getRecommendationPlan(servingSize)
    setForwardStep()
  }, setLoading)

const currentStep = ref<number>(1)
const resetStep = () => (currentStep.value = 1)
const setBackStep = () => (currentStep.value = Math.max(1, currentStep.value - 1))
const setForwardStep = () => (currentStep.value = Math.min(3, currentStep.value + 1))

const submitFeedingPlan = () =>
  loadingWrapper(async () => {
    if (
      currentSubscription.value &&
      currentSubscriptionContract.value &&
      currentDog.value &&
      selectedServingSize.value
    ) {
      await updateFeedingPlan(
        currentSubscription.value.subscriptionId,
        currentSubscriptionContract.value.id,
        currentDog.value.identifier,
        currentSubscription.value.mealPlanTemplateId as FeedingPlanType,
        selectedServingSize.value,
      )
      await subscriptionsStore.load()
      currentRecommendationPlan.value = await getRecommendationPlan(selectedServingSize.value)
      setForwardStep()
    }
  }, setLoading)

const backToDogProfiles = () => {
  resetStep()
  router.push({
    name: 'dogs',
  })
}

onBeforeMount(() => {
  loadingWrapper(async () => {
    await Promise.all([animalsStore.load(), subscriptionsStore.load(), ordersStore.load()])
    await setCurrentRecommendationPlan()
  }, setLoading)
})
</script>

<template>
  <ion-page>
    <EditServingSize
      v-if="currentStep === 1 && currentServingSize"
      :dog-name="currentDog?.name"
      :initial-serving-size="currentServingSize"
      :recommended-serving-size="recommendedServingSize"
      :loading="loading"
      @cancel="backToDogProfiles"
      @review-changes="setSelectedRecommendationAndMoveForward"
    />
    <EditServingSizePlanConfirm
      v-if="currentStep === 2 && currentRecommendationPlan && selectedRecommendationPlan"
      :loading="loading"
      :dog-name="currentDog?.name"
      :current-recommendation-plan="currentRecommendationPlan"
      :selected-recommendation-plan="selectedRecommendationPlan"
      :next-delivery-date="nextDeliveryDate"
      @cancel="setBackStep"
      @submit-plan="submitFeedingPlan"
    />
    <EditServingSizePlanSubmission
      v-if="currentStep === 3"
      :dog-name="currentDog?.name"
      :next-delivery-date="nextDeliveryDate"
      @dismiss="backToDogProfiles"
    />
  </ion-page>
</template>
