export const loadingWrapper = async <T>(cb: () => Promise<T>, setLoading: (loading: boolean) => void): Promise<T> => {
  setLoading(true)
  try {
    const res = await cb()
    setLoading(false)
    return res
  } catch (e) {
    console.error(e)
    setLoading(false)
    throw e
  }
}
