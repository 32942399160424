import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { Preferences } from '@capacitor/preferences'
import { useRefreshToken } from '@/composables/useRefreshToken'
import { isTokenExpired } from '@/utils/isTokenExpired'

const ACCESS_TOKEN_KEY = 'access_token'
const CUSTOMER_TOKEN_KEY = 'customer_token'

export const useAuthStore = defineStore('auth', () => {
  const savedToken = ref<string | null>()
  const isAuthenticated = computed(() => !!savedToken.value)

  const getAccessToken = async (): Promise<string | null> => {
    const ret = await Preferences.get({ key: ACCESS_TOKEN_KEY })
    return ret.value
  }

  const getCustomerToken = async (): Promise<string | null> => {
    const ret = await Preferences.get({ key: CUSTOMER_TOKEN_KEY })
    return ret.value
  }

  const setAccessToken = async (token: string): Promise<void> => {
    savedToken.value = token
    await Preferences.set({
      key: ACCESS_TOKEN_KEY,
      value: token,
    })
  }

  const setCustomerToken = async (token: string): Promise<void> => {
    savedToken.value = token
    await Preferences.set({
      key: CUSTOMER_TOKEN_KEY,
      value: token,
    })
  }

  const removeAccessToken = async (): Promise<void> => {
    savedToken.value = null
    return await Preferences.remove({ key: ACCESS_TOKEN_KEY })
  }

  const removeCustomerToken = async (): Promise<void> => {
    savedToken.value = null
    return await Preferences.remove({ key: CUSTOMER_TOKEN_KEY })
  }

  const checkTokenIsExpired = async (): Promise<void> => {
    const token = await getAccessToken()
    if (token) {
      savedToken.value = token
      if (isTokenExpired(token)) {
        await removeAccessToken()
        // Attempt token refresh
        await useRefreshToken()
      }
    }
  }

  return {
    isAuthenticated,
    checkTokenIsExpired,
    getAccessToken,
    setAccessToken,
    removeAccessToken,
    getCustomerToken,
    setCustomerToken,
    removeCustomerToken,
  }
})
