<script setup lang="ts">
import { IonFooter, IonToolbar } from '@ionic/vue'
import { useAuthStore } from '@/stores/auth'
import MenuSegments from '@/components/MenuSegments.vue'

const authStore = useAuthStore()
</script>

<template>
  <ion-footer v-if="authStore.isAuthenticated">
    <ion-toolbar>
      <MenuSegments />
    </ion-toolbar>
  </ion-footer>
</template>
