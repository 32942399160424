export const productNameToTitle = (productName: string) => {
  if (!productName) {
    return ''
  }
  const product = productName.split(' - ')
  if (product.length < 2) return ''

  return product[1]
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}
