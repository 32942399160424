import type { IdentifyParams, PageParams } from '@segment/analytics-next/dist/types/core/arguments-resolver'
import type { User } from '@segment/analytics-next'
import type { SegmentEvents } from '@/types/segment'
import snakecaseKeys from 'snakecase-keys'
import { withTimeout } from '@/utils/withTimeout'
import type { AnalyticsBrowser } from '@segment/analytics-next'

export const getSegment = (): AnalyticsBrowser | undefined => {
  return window.segment
}

const transformEvent = (eventData: Record<string, unknown>): Record<string, unknown> => {
  const snakecased = eventData ? snakecaseKeys(eventData) : {}

  return snakecased
}

const SEGMENT_ANONYMOUS_USER_ID_KEY = 'ajs_anonymous_id'

const getOrCreateAnonymousId = (): string => {
  const existingAnonymousId = localStorage.getItem(SEGMENT_ANONYMOUS_USER_ID_KEY)

  if (existingAnonymousId) {
    return existingAnonymousId
  }

  const uuid = crypto.randomUUID()
  localStorage.setItem(SEGMENT_ANONYMOUS_USER_ID_KEY, uuid)

  return uuid
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useSegment = () => {
  const track = async <K extends keyof SegmentEvents>(eventName: K, eventData?: SegmentEvents[K]): Promise<void> => {
    const transformedData = transformEvent(eventData ?? {})
    const segment = getSegment()

    try {
      segment?.track(eventName, transformedData)
    } catch (error) {
      console.error(error)
    }
  }

  const identify = (...args: IdentifyParams): void => {
    getSegment()?.identify(...args)
  }

  const page = (...args: PageParams): void => {
    getSegment()?.page(...args)
  }

  const getUser = async (): Promise<User | undefined> => {
    try {
      const segment = getSegment()

      if (!segment) {
        return undefined
      }

      const user = await withTimeout<User>(() => segment.user(), 2_000)

      return user
    } catch (err) {
      console.error('Failed to fetch Segment User', err)

      return undefined
    }
  }

  const getAnonymousId = async (): Promise<string> => {
    const user = await getUser()

    if (user) {
      const id = user.anonymousId()

      if (id) {
        return id
      }
    }

    // If Segment failed to initialize, we'll create our own anonymous ID
    return getOrCreateAnonymousId()
  }

  return {
    track,
    identify,
    page,
    getAnonymousId,
  }
}
