export enum AvailableSlotName {
  AM = '12am - 7am',
  PM = '8am - 6pm',
}

export interface DeliveryTime {
  slot: AvailableSlotName
  disabled?: boolean
}

export interface AvailableDates {
  date: string
  timeSlots?: DeliveryTime[]
}
