import { initClient, InitClientArgs } from '@ts-rest/core'
import { contract } from '@repo/api-contracts'
import { useAuthStore } from '@/stores/auth'
import { jwtDecode } from 'jwt-decode'
import { useRefreshToken } from '@/composables/useRefreshToken'

export const useClient = async () => {
  const authStore = useAuthStore()
  let accessToken = await authStore.getAccessToken()
  const customerToken = await authStore.getCustomerToken()

  const baseHeaders = {
    'Content-Type': 'application/json',
  } as Record<string, string>

  if (accessToken) {
    const decoded = jwtDecode(accessToken)
    const expired = new Date((decoded.exp as number) * 1000)
    const expiredMinusTwoMin = expired.setMinutes(expired.getMinutes() - 2)
    if (Date.now() > expiredMinusTwoMin) {
      const { success } = await useRefreshToken()
      if (success) {
        // new access token
        accessToken = await authStore.getAccessToken()
      }
    }
  }

  baseHeaders['Authorization'] = 'Bearer ' + accessToken

  if (customerToken) {
    baseHeaders['X-Customer-Token'] = customerToken
  }

  const config = {
    baseUrl: import.meta.env.VITE_API_BASE_URL,
    baseHeaders,
    credentials: 'include',
  } as InitClientArgs
  const client = initClient(contract, config)
  return client
}
